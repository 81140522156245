.pricing-plot {
    width: 100%;
}

.pricing-summary {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    text-align: center;
}

.summary-box {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    flex: 1;
    margin: 0 10px;
    background-color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border: 1px solid #e0e0e0;
}

.summary-box:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.1);
}

.summary-box h2 {
    margin: 0 0 8px 0;
    font-size: 28px;
    font-weight: 600;
}

.summary-box p {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    color: #555;
    letter-spacing: -0.01em;
}

.chart-container {
    height: 400px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: nonel

}

.chart-inner {
    height: 100%;
    width: 100%;
    position: relative;
}

.posts-referenced {
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;
    padding-top: 20px;
}

.posts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.posts-header h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
}

.category-label {
    margin: 0 0 16px 0;
    font-size: 13px;
    font-weight: 500;
    color: #555;
    letter-spacing: -0.01em;
}

.header {
    margin-bottom: 24px;
}

.header .title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin: 0 0 8px 0;
}

.header .subtitle {
    font-size: 14px;
    color: #666;
    margin: 0;
} 