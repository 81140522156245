.nps-metrics-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin: 24px 0;
}

.nps-metric-card {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.nps-metric-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

.metric-period {
  font-size: 0.9rem;
  color: #64748b;
  font-weight: 500;
  margin-bottom: 12px;
}

.metric-row {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 12px;
}

.metric-label {
  color: #64748b;
  font-size: 0.85rem;
}

.metric-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1e293b;
}

.metric-value.positive {
  color: #059669;
}

.metric-value.negative {
  color: #dc2626;
}

.metric-value.neutral {
  color: #d97706;
}

.sample-size {
  font-size: 0.85rem;
  color: #64748b;
  text-align: right;
  margin-top: 8px;
}

.nps-header {
  font-size: 1.25rem;
  color: #1e293b;
  font-weight: 600;
  margin-bottom: 16px;
}

.product-selector {
    min-width: 200px;
    margin-right: 10px;
}

.nps-chart-container {
    padding: 20px;
    background: white;
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.chart-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.chart-control-group {
    display: flex;
    align-items: center;
    gap: 12px;
}

.settings-button {
    padding: 8px 12px;
    background: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.settings-button:hover {
    background: #e9e9e9;
}

.settings-panel {
    background: #f9f9f9;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
}

.settings-panel h3 {
    margin: 0 0 16px 0;
    font-size: 16px;
    color: #333;
}

.settings-group {
    margin-bottom: 16px;
}

.settings-group:last-child {
    margin-bottom: 0;
}

.settings-group label {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
    font-weight: 500;
}

.settings-group input {
    padding: 6px 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 80px;
}

.setting-description {
    margin: 4px 0 0 0;
    font-size: 13px;
    color: #666;
}

.metric-cards {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 24px;
}

.metric-card {
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    padding: 24px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.metric-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-color: #d1d5db;
}

.metric-card h3 {
    margin: 0 0 20px 0;
    font-size: 16px;
    font-weight: 500;
    color: #6b7280;
}

.metric-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 12px;
}

.metric-label {
    font-size: 14px;
    color: #6b7280;
}

.metric-value {
    font-size: 24px;
    font-weight: 600;
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
}

.metric-value.positive {
    color: #059669;
}

.metric-value.neutral {
    color: #d97706;
}

.metric-value.negative {
    color: #dc2626;
}

.metric-value.rating {
    color: #1e293b;
}

.sample-size {
    margin-top: 16px;
    padding-top: 12px;
    border-top: 1px solid #e5e7eb;
    font-size: 13px;
    color: #6b7280;
    text-align: right;
}

.metric-details {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 14px;
    color: #6b7280;
}

.metric-details span {
    display: flex;
    align-items: center;
    gap: 4px;
}

.chart-wrapper {
    background: white;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .chart-controls {
        flex-direction: column;
        gap: 16px;
    }

    .chart-control-group {
        width: 100%;
        justify-content: space-between;
    }

    .metric-cards {
        grid-template-columns: 1fr;
        gap: 16px;
    }

    .metric-card {
        padding: 20px;
    }

    .metric-value {
        font-size: 20px;
    }
}