input {
  font-size: 16px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.post-context-link{
  font-size: 12px;
  padding-right: 10px;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
  color: #007bff;
}

.search-bar-container {
  background-color: #1042c1;
  height: 200px;
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.search-box-exterior {
    position: relative;
    width: 100%;
}

.search-bar-header{
  color: #fff;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 20px;
  border:0;
}

.search-bar-header h1{
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
}

.search-bar-header a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

/* Optionally, style the link as plain text */
.search-bar-header a:hover {
  text-decoration: none; /* Remove underline on hover */
  color: inherit; /* Inherit text color on hover */
}

.search-bar-input{
  font-size: 16px;
  border: none;
  width: 100%;
  margin-left: 10px;
  background-color: #fbfbfb;
}

.search-bar-input:focus{
  outline: none;
}

.search-bar-button{
    margin: 5px;
    height: 40px;
    width: 65px;
    border-radius: 5px;
    border: 0;
    color: #1042c1;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    background-color: #fbfbfb;
    transition: background-color 0.15s;
    cursor: pointer;
}

.search-bar-button:hover{
    background-color: #1042c1;
    color: #fff;
}

.search-results-container{
  display: flex;
  justify-content: center;
}

.logo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  grid-auto-rows: 120px; /* Height of each row */
  width: 100%;
  max-width: 1600px;
}

@media (min-width: 1200px) {
  .logo-grid {
    grid-template-columns: repeat(3, minmax(250px, 1fr)); /* Change the column width and allow for more columns on smaller screens */
  }
}

.logo-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Background color for each logo item */
  position: relative; 
  display: inline-block;

}

.logo-img {
  /* max-width: var(--specific-item-size, 100%); /* Default to 80px if specificItemSize is not provided */
  /* max-height: var(--specific-item-size, 100%);   */
  max-width: 70%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.logo-container{
  width: 100%; /* Inherit the width of the container */
  height: 100%; /* Inherit the height of the container */
  position: relative;
  position: relative;
  display: inline-block;
}

.logo-container-selected{
  background-color: #f4f4f4;
}


@media screen and (min-width: 768px) {
  .logo-container:hover {
    background-color: #f4f4f4;
  }
}

.no-hover:hover{
  background-color: #fff;
}

.quote-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 12px;
}

.quote-section-header h2 {
    font-size: 1.5rem;
    margin: 0;
    color: #333;
    font-weight: 600;
}

.close-button {
    color: #666;
}

.quote-display {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.quote {
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
    border: 1px solid #e0e0e0;
}

.quote:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.quote-text {
    font-size: 0.95rem;
    line-height: 1.6;
    color: #444;
    margin: 0;
}


.coverage-container{
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customer-header{
  text-align: center;
  font-size: 24px;
}

.date-pickers{
  display: flex;
  justify-content: center;
  position: relative;
}

.date-picker{
  margin: 10px;
  background-color: #fff;
  position: relative;
}

.filter-button{
  margin-top: 10px;
  background-color: #f6f6f6;
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
  font-size: 14px;
}

.filter-button:hover{
  background-color: #e6e6e6;
}

.close-filter-button{
  margin-top: 10px;
  background-color: rgba(0,0,0,0);
  border: 0;
}

.close-filter-button:hover{
  background-color: #f6f6f6;
}

.date-range-picker-container{
  display: flex;
  justify-content: center;
  z-index: 0;
  position: relative;
  padding-bottom: 10px;
}

.date-range-picker-section{
  width: 71%;
}

.date-range-picker-header{
  display: flex;
  justify-content: right;
  align-items: center;
}

.apply-filter-button{
  margin-top: 10px;
  height: 38px;
  width: 100px;
  background-color: #f6f6f6;
  border: 0;  
  border-radius: 10px;
}

.apply-filter-button:hover{
  background-color: #e6e6e6;
}

.post-button{
  background-color: rgba(0,0,0,0);
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
  color: #1042c1;
}

.post-button:hover{
  background-color: #e6e6e6;
}

.post-bookmark{
  display: flex;
  justify-content: right;
  margin-top: 0px;
}

.post{
  overflow-wrap: break-word;
  position: relative; /* Add this if not already present */
  margin-left: 30px; /* Add this to make room for the handle */
}

.collapse-handle {
    position: absolute;
    left: -30px; /* Change from 0 to -24px to move it outside the post */
    top: 0;
    bottom: 0;
    width: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
    border-radius: 10px;
}

.collapse-handle:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.05);
}

.post.collapsed {
    padding: 8px 0;
    min-height: 40px;
}

/* :target{
    background-color: #e6e6e6;
    border: 30px solid #e6e6e6;
    box-shadow: 2px 10px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
} */

.post-container{
  display: flex;
  flex-direction: column;
  justify-content: top;
}

.post-title{
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    padding-top: 10px;
    margin: 0px;
}

.md {
    padding: 0px;
    margin: 0px;
}

.post-subtype{
    font-size: 14px;
    background-color: #ffbe9e;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-radius: 15px;
    width: fit-content;
    margin: 0px;
}

.post-link{
  font-size: 12px;
}
.post-time{
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
}

.my-bookmarks-section{
  /* background-color: #fff; */
  display: flex;
  justify-content: right;
  align-items: center;
}

.my-bookmarks-button{
  background-color: #fff;
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  font-size: 14px;
  width: 200px;
}

.my-bookmarks-button:hover{
  background-color: #f6f6f6;
}

.my-bookmarks-toolbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    font-size: 12px;
}

.header-bar{
  background-color: #1042c1;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  width: 100%;
}

.header-bar-container{
    position: sticky;
    top: 0; bottom: 0; left: 0;
    z-index: 1000;

}

.toolbar-button{
  font-size: 14px;
  color: #fff;
  background-color: #1042c1;
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  /* height: 30px; */
}

.bookmarks-link{
  display: flex;
  align-items: center;
  text-decoration: none;
}

.toolbar-button:hover{
  background-color: #fff;
  color: #1042c1;
}

.toolbar-buttons-right{
  display: flex;
  justify-content: right;
  align-items: center;
}

.toolbar-buttons-left{
  display: flex;
  justify-content: left;
  align-items: center;
}

.centered-icon-button{
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toolbar-button,
.toolbar-button:hover,
.toolbar-button:focus,
.toolbar-button:active {
    text-decoration: none;
}

.toolbar-buttons-right a,
.toolbar-buttons-left a ,
a {
    text-decoration: none;
}

/* ::selection {
  background: yellow;
  color: black;
  background: #3297FD;
  color: #fff;
} */

/* .landing-page ::selection {
  background: #3297FD;
  color: #fff;
} */

.icon-button-label {
    background-color: inherit;
    border: none;
    color: inherit;
    font-size: 14px;
    text-decoration: none;
}


.icon-button-label{
    background-color: inherit;
    border: none;
    color: inherit;
    font-size: 14px;
    text-decoration: none !important;
}

.label-padded{
  padding-left: 5px;
}

.bookmark-icon{
  padding: 0;
}

.bookmarks-link{
  color: inherit;
}

.bookmarks-link:hover{
  color: inherit;
}

.buttons-with-user{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sneaky-button{
    border: none;
    padding: none;
    margin: none;
    background-color: rgba(0,0,0,0);
}

.testimonial-text{
  font-size: 16px;
}

.try-now-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 100px;
}

.try-now-button{
  margin-top: 20px;
  background-color: #1042c1;
  color: #fff;
  width: 300px;
  height: 60px;
  border: 3px solid #1042c1;
  border-radius: 40px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}

.try-now-button:hover{
  background-color: #0d3599;
  color: #fff;
}

.try-now-text-container{
  align-items: center;
}

.try-now-text-container > * {
  margin: 0;
  padding: 0;
}


.try-now-subtitle{
  margin-top: 10px;
  font-size: 12px;
  font-style: italic;
  color: #000;
}

.try-now-section{
  text-align: center;
}

.try-now-header{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.modal-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}


.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.modal-close-button:hover {
  background-color: #f6f6f6;
}

.logged-in-container {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form-container h1{
  color: #000;
  font-weight: 600;
  font-size: 20px;
}

.form-container h3{
  font-weight: 400 !important;
  font-size: 16px !important;
}

.login-modal{
  width: 100%;
  text-align: center;
  z-index: 10000;
}

.ReactModal__Content{
  max-width: 90%;
} 

.get-started-button{
  color: #fff;
  background-color: #1042c1;
  border: 3px solid #1042c1;
  border-radius: 40px;
  /* font-size: 20px !important; */
  height: 50px !important;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Post Details Page */
.post-details-container{
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.post-details{
  width: 80%;
  margin-top: 10px;
}

.post-main{
  margin-left: 40px;
}

.post-kid{
  margin-left: 80px;
}

.bookmark-menu {
    top: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 0px;
    margin: 0px;
    padding: 5px;
    max-height: 200px;
    overflow-y: auto;
    position: fixed; /* Change from absolute to fixed */
    z-index: 1000;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-y: auto; 
}

.bookmark-menu::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.bookmark-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.bookmark-menu::-webkit-scrollbar-thumb {
  background: #9f9f9f;
}

/* Handle on hover */
.bookmark-menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bookmark-menu ul{
  list-style-type: none;
  padding-left: 10px;
  padding: 0px;
  margin: 0px;
}

.bookmark-menu button:hover{
  background-color: #f6f6f6;
}

.bookmark-menu button.bookmarked{
  font-weight: 900;
}

.bookmark-menu button{
  background-color: #fff;
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #1042c1;
  padding: 5px;
  font-weight: 700;
  width: 100%;
}

.add-folder-button-container{
  display: flex;
  justify-content: left;
}

.bookmarked-icon{
  color: #1042c1;
}

.bookmark-check{
  color: #1042c1;
  padding-left: 5px;
  padding-bottom: 2px;
}

.new-folder-modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Add additional styles for modal appearance */
  width: 300px; /* Adjust width as needed */
  height: 200px; /* Adjust height as needed */
  background-color: white; /* Example background color */
  border-radius: 5px; /* Example border radius */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Example box shadow */
  padding-left: 20px;
  padding-right: 20px;
  z-index: 999;
}

.new-folder-modal .title{
  color: #1042c1;
  font-size: 14px;
}

.new-folder-modal .inputs-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
}

.new-folder-modal .input{
  margin-top: 10px;
  background-color: #fff;
  border: none;
  border-bottom: 2px solid #1042c1;
}

.new-folder-modal .bottom-bar{
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.new-folder-modal .modal-button{
  background-color: #fff;
  border: 3px solid #1042c1;
  border-radius: 40px;
  font-size: 14px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #1042c1;
}

.new-folder-modal .modal-button:hover{
  background-color: #1042c1;
  color: #fff;
}

.what-we-do-text{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.what-we-do-text p{
    font-size: 18px;
}

#services .services-text{
  padding-left:30px;
  padding-right:30px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.services-section {
    padding: 80px 0;
    background-color: #f8f9fa;
  }
  
  .container {
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .feature-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .feature-icon-wrapper {
    background-color: #1042c1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .feature-icon {
    font-size: 2.5rem;
    color: #fff;
  }
  
  .feature-content {
    padding: 20px;
  }
  
  .feature-title {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  .feature-description {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .features-grid {
      grid-template-columns: 1fr;
    }
  }

#services{
    background-color: #fff;
}

#services  p{
  padding-top: 10px;
  text-align: left;
}

#services h3{
    height: 40px;
    padding: 0;
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

#services .service-icon{
    color: #fff;
    background-color: #1042c1;
    border-radius: 50%;
    padding: 20px;
    width: 70px;
    height: 70px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}


.my-bookmarks {
  width: min(80%, 1000px);
  padding-top: 0;
}

.my-bookmarks-container{
  display: flex;
  justify-content: center;
}


.folder-dropdown{
  margin-left: 10px;
  background-color: #fff;
  border: 3px solid #1042c1;
  border-radius: 20px;
  display: flex;
  align-items: center;
  color: #1042c1;
  font-weight: 700;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  height: 35px;
}

.folder-dropdown option{
  background-color: #fff;
  border: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  color: #1042c1;
  font-weight: 700;
  width: 100%;
}

.my-bookmarks-header{
  font-size: 22px;
}

.bookmark-folder-toolbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bookmark-folder-toolbar button{
  font-size: 12px;
}

.select-folder{
  font-size: 14px;
}

.no-posts-message{
  text-align: center;
  padding-top: 150px;
  padding-bottom: 20px;
}

.no-posts-message h2{
  font-size: 26px;
  font-weight: 600;
}


.progress-indicator{
  display: flex;
  justify-content: center;
  padding-top: 200px;
  padding-bottom: 20px;
}

.summary-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
}

.summary-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.generation-time{
  padding-top: 10px;
  font-size: 12px;
  font-style: italic;
}

.new-bookmarks-text{
  font-size: 12px;
  padding: 0;
  margin: 0;
  margin-left: 10px;
  font-style: italic;
}

.summarize-button{
  background-color: #fff;
  color: #1042c1;
  border: 3px solid #1042c1;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  height: 30px;
  margin-left: 5px;
}
.summarize-button:hover{
  background-color: #1042c1;
  color: #fff;
}

.my-bookmarks-icon{
    color: #1042c1;
    padding: 0;
    margin: 0;
}

.my-bookmarks-icon:hover{
    background-color: #e6e6e6;
}
  

.summary-progress{
  padding-top: 50px;
}

.progress-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-generating-label{
  padding-top: 10px;
  font-style: italic;
  color: #1042c1;
}

.summary-header{
  display: flex;
  justify-content: space-between;
}

.summarize-button{
  display: flex;
  align-items: center;
}

.refresh-icon{
  padding-right: 5px;
}

.summary-button-gray{
  background-color: #f6f6f6;
}
.summary-button-gray:hover{
  color: #f6f6f6;
}

.new-bookmarks-text{
  text-align: center;
}

.similar-posts{
  padding:0;
  margin:0;
}

.similar-posts h1 {
  padding:0;
  margin:0;
}

.similar-posts-container{
  padding:0;
  padding-top: 20px;
  margin:0;
  padding-left: 10px;
}

.similar-posts-header{
  padding-top: 20px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.original-post{
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #f6f6f6;
  border-radius: 20px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
}


.buttons-left{
  display: flex;
  justify-content: left;
  align-items: center;
}

.button-icon{
  padding-right: 0px;
}

.news-header-container{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.news-dropdown {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #1042c1;
  border-radius: 20px;
  background-color: rgba(0,0,0,0);
  color: #1042c1;
}

/* Styles for the options */
.news-dropdown option {
  background-color: #fff;
  color: #333;
}

.faded-gray-button{
  color: #b5b5b5;
}

.faded-gray-button:hover{
  color: #000;
}

.undo-delete-button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 15px;
  border-radius: 20px;
  background-color: #e6e6e6;
  font-size: 12px;
  border: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  gap: 5px;
}

.undo-delete-button:hover{
  background-color: #ccc;
}

.deleted-post-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 12px 16px;
}

.deleted-post-container p {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
}


.buttons-hidden{
  display: flex;

}

.navbar-header-title{
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
}

.navbar-header-title a {
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
}

/* Optionally, style the link as plain text */
.navbar-header-title a:hover {
  text-decoration: none; /* Remove underline on hover */
  color: inherit; /* Inherit text color on hover */
}

.sign-up-button{
  width: 80px;
  background-color: #fff;
  color: #1042c1;
  margin-right: 10px;
  border: 3px solid #1042c1;

}

.sign-up-button:hover{
  background-color: #1042c1;
  color: #fff;
  border: 3px solid #fff;
}

.search-bar-results{
    display: flex;
    justify-content: center;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    margin-top: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 400;
    position: relative;
}

.watchlist-button{
    align-items: center;
    background-color: #fff;
    border: 3px solid #1042c1;
    color: #1042c1;
    border-radius: 20px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 10px;
    display: flex;
}

.watchlist-button-label{
  font-size: 12px;
}

.watchlist-button:hover{
    background-color: #f6f6f6;
    /* color: #fff; */
}

.on-watchlist{
    background-color: #1042c1;
    color: #fff;
}

.on-watchlist:hover{
    /* background-color: #fff; */
    /* color: #1042c1; */
    background-color: #1042c1;
}

.company-header-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-header-title img {
  width: 30px;
  height: 30px;
}

.search-box{
  background-color: #fbfbfb;
  border: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
  z-index: 1;
}


.my-bookmarks-display-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.my-bookmarks-button-container{
    display: flex;
    justify-content: right;
    align-items: center;
    gap:10px;
}

.share-modal{
    background-color: #fff;
    /* max width should be min of 200px or 80% */
    /* max-width: min(600px, 100%); Adjust width as needed */
    /* max-height: 300px; */
    min-width: 350px;
    width: 80%;
    max-width: min(95%, 800px);
    z-index: 1000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
}

.share-modal-header{
    padding-left: 15px;
}

.share-background{
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.share-modal-search{
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    height: 35px;
}

.share-modal-search:focus{
    outline: none;
}

.share-modal-close {
    float: right;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    margin-top: 5px;
    margin-right: 10px;
}
.share-modal-close:hover,
.share-modal-close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5;
}

.copy-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.copy-button:hover{
    background-color: #ccc;
}

.copy-icon{
    padding-right: 5px;
}

.share-modal-footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.share-modal-done{
    background-color: #1042c1;
    border-radius: 20px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #fff;
    border: 0;
}

.share-modal-done:hover{
    background-color: #4068cd;
}

.folder-person-name{
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    margin-top: 7px;
}

.folder-access-row p{
    font-size: 12px;
    margin-bottom: 7px;
}

.share-modal h5{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px;
}


.folder-access-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    /* border-bottom: 1px solid #e0e0e0; */
    margin-bottom: 2px;
}

.folder-access-row:hover{
    background-color: #f6f6f6;
}

.folder-access-row-left {
    display: flex;
    align-items: center;
}

.folder-access-profile-picture {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.folder-access-info {
    margin-left: 10px;
}

.folder-access-row-right {
    display: flex;
    align-items: center;
}

.folder-access-row select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
}

.folder-access-row select:focus {
    outline: none;
}

.folder-access-row select option {
    background-color: white;
}

.folder-access-row select option:checked {
    background-color: #e0e0e0;
}

.folder-access-row select option:hover {
    background-color: #e0e0e0;
}

.folder-access-row select option:active {
    background-color: #e0e0e0;
}

.folder-access-row select option:focus {
    background-color: #e0e0e0;
}

.share-modal-search-container{
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.share-modal-search-dropdown{
    /* This search dropdown should appear over all the other elements, and not move them */
    position: absolute;
    z-index: 1000;
    background-color: #fbfbfb;
    border: 1px solid #e0e0e0;
    width: calc(100% - 30px); /* 100% width minus the total left and right margin */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.shared-bookmarks-button-group{
    display: flex;
    justify-content: right;
    align-items: center;
    padding-top: 10px;
}


/* Create the CSS for this:

            // modal that pops up to share the post
            // Has a button to let you copy the link
            // Has icon buttons for twitter and email
            // has an x in the top right corner to close the modal
            <div className='share-post-modal'>
                <div className='share-post-modal-header-bar'>
                    <button className='close-share-post-modal' onClick={() => setShowShareMenu(false)}>X</button>
                </div>
                <div className='share-post-modal-body'>
                    <h1>Share Post</h1>
                    <button className='copy-link-button'>Copy Link</button>
                    <div className='share-post-icons'>
                        <button className='twitter-share-button'>Twitter</button>
                        <button className='email-share-button'>Email</button>
                    </div>
                </div>
            </div>
      */

.share-post-modal{
    background-color: #fff;
    /* max width should be min of 200px or 80% */
    /* max-width: min(600px, 100%); Adjust width as needed */
    /* max-height: 300px; */
    min-width: 350px;
    z-index: 1000;
    border-radius: 10px;

}

.share-post-modal-body{
    min-width: min(350px, 90vw);
}

.share-post-modal-header-bar{
    display: flex;
    justify-content: space-between;
    margin-left: 15px;
}

.close-share-post-modal {
    float: right;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
    margin-top: 5px;
    margin-right: 10px;
}

.close-share-post-modal:hover,
.close-share-post-modal:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .5;
}

.copy-link-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.copy-link-button:hover{
    background-color: #ccc;
}

.copy-icon{
    padding-right: 5px;
}

.share-post-icons{
    display: flex;
    gap: 10px;
}

.gray-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    padding-bottom: 5px;
    padding-top: 5px;
    color: black;
}

.blue-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1042c1;
    border: 0;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 25px;
    padding-bottom: 5px;
    padding-top: 5px;
    color: #fff;
}

.gray-button:hover{
    background-color: #ccc;
}

.social-card-export-container{
  padding:15px;
  background-color: #fff;
}

.social-card{
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden; /* Ensures child content doesn't overflow the parent's border radius */
}


.social-card-text{
  padding-left: 25px;
  padding-right: 25px;
}

.social-card-source{
  font-style: italic;
  margin-left: 25px;
  margin-bottom: 10px;
  font-size: 12px;
}

.social-card-date{
  font-style: italic;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-top: 10px;
  font-size: 12px;
}

.social-card-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1042c1;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #fff;
  margin-bottom: 0px;
  width: 100%;
}

.social-card-text-container{
  width: 100%;
}

.icon{
  padding-right: 5px;
}

.social-card-button-footer{
  margin-left: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

/* Styles for vertical layout when screen width is less than 375px */
@media (max-width: 600px) {
  .social-card-button-footer {
      flex-direction: column;
      align-items: center; /* Center align items if needed */
  }
  .social-card-button-footer .gray-button {
      width: 100%; /* Ensure buttons take full width of their container */
  }
}
/* Styles for vertical layout when screen width is less than 375px */
@media (max-width: 600px) {
  .share-post-icons {
      flex-direction: column;
      align-items: center; /* Center align items if needed */
      width: 100%;
      gap: 5px;
  }

  .button-container {
      width: 100%; /* Make each button container take full width */
      padding: 0;
  }

  .social-card-button-footer .gray-button {
      width: 100%; /* Ensure buttons take full width of their container */
  }
}

/* Styles for vertical layout when screen width is less than 375px */
@media (max-width: 1000px) {
  .share-post-icons {
      width: 100%;
      margin-top: 5px;
  }

  .button-container {
      width: 100%; /* Make each button container take full width */
      padding: 0;
  }


  .social-card-button-footer {
      flex-direction: column;
      align-items: center; /* Center align items if needed */
  }

  .social-card-button-footer .gray-button {
      width: 100%; /* Ensure buttons take full width of their container */
  }
}

.folder-settings-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.folder-settings-container{
  padding-left: min(5%, 100px);
  padding-right: min(5%, 100px);
}


.scene {
  padding-top: 10px;
}

.scene-short {
    padding-top: 10px;
}

.folder-settings-name{
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 5px;
}

.tag{
  background-color: #e6e6e6;
  color: #555;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
}

.tag:hover{
  background-color: #ccc;
}

.tags-container{
  display: flex;
  gap: 5px;
  align-items: left;
  margin-bottom: 5px;
}

.tag-delete-button{
  padding: 3px;
}

.tag-delete-button-container{
  cursor: pointer;
  border-radius: 15px;
}

/* .tag-delete-button-container:hover{
  background-color: #bfbfbf;
} */

.folder-settings-middle{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.folder-settings-main{
  width: 60%;
}

.folder-settings-side-panel{
  width: 35%;
  background-color: #f6f6f6;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
}

.folder-settings-side-panel-container{
  padding: 20px;
}

.edit-folder-name-buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-tag-input{
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.company-tag-input:focus{
  outline: none;
}

.company-tag-input-container{
  display: flex;
  justify-content: left;
  align-items: center;
}

.company-tag-container{
  display: flex;
  justify-content: left;
  align-items: center;
}

.delete-button{
  color: #fff;
  background-color: #c20404;
  border: 0;
  border-radius: 15px;
  padding-top:5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
}

.delete-button:hover{
  background-color: #960202;
}

.delete-confirmation{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 370px;
  padding: 20px;
  border-radius: 20px;
  padding-bottom: 10px;
}

.delete-confirmation-buttons{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.delete-section{
  margin-top: 20px;
}

.folder-name{
  font-size: 36px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.collapsible-section-container{
  background-color: #f6f6f6;
  border-radius: 20px;
  padding-left: 30px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 10px;
  margin-bottom: 10px;
}

.collapsible-section-container h4{
  margin-top: 2px;
  margin-bottom: 0px;
}

.company-products-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-products{
  margin-right: 60px;
}

.no-background{
  background-color: rgba(0,0,0,0);
}

.small-title{
  font-size: 18px;
  font-weight: 500;
}

.small-divider{
  margin-top: 5px;
  margin-bottom: 5px;
}

.folder-buttons-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    gap: 10px;
    /* background-color: red; */
}

.folder-button-text{
    overflow: hidden; /* Hide overflow content */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflowed text */
    white-space: nowrap; /* Prevent text wrapping */
    margin-left: 7px;
    flex: 1;
    min-width: 0;
    padding-top: 2px;
}

.folder-button-container{
    display: flex;
    justify-content: left;
    align-items: center;
    /* background-color: cyan; */
    min-width: 160px;
    max-width: 400px;
    width: 100%;
    overflow: hidden; /* Add this to prevent content from spilling out */
}

.folder-button{
    background-color: #e6e6e6;
    border-radius: 10px;
    font-size: 12;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 2px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #1f1f1f;

    border: 2px solid #e6e6e6;
    width: 100%;
    min-width: 0; /* Add this to allow flex items to shrink below their minimum content size */
    overflow: hidden; /* Add this to prevent content from spilling out */
}

.folder-button:hover{
    background-color: #ccc;
    border: 2px solid #ccc;
    color: #434746;
}

.uncat-header{
    margin-top: 50px;
}

.folder-button-target{
    border: 2px solid #1042c1;
}

.folder-button-dropdown{
    border-radius: 10px;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.folder-button-icon {
    flex-shrink: 0; /* Prevent icon from shrinking */
}

.folder-button-more-button{
    border-radius: 15px;
    height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    background-color: rgba(0,0,0,0);
    border: 0;
}

.folder-button-more-button:hover{
    background-color: #b5b5b5
}


.folder-button-dropdown-item:hover{
    background-color: #e6e6e6;
}

.folder-container{
    margin-left: 20%;
    margin-right: 20%;
}

.new-folder-name-input{
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
}

.new-folder-name-input:focus{
    outline: none;
}

.centered{
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-title{
    font-family: "Inter", sans-serif;
    font-weight: 700;
}

.split{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vertical{
    display: flex;
    flex-direction: column;
}

.large-break{
    margin-top: 15px;
    margin-bottom: 15px;
}

.break {
    margin-top: 10px;
    margin-bottom: 10px;
}

.small-break{
    margin-top: 5px;
    margin-bottom: 5px;
}

.tiny-break{
    margin-top: 2px;
    margin-bottom: 2px;
}

.button-minimal{
    background-color: inherit;
    border: none;
    color: #000;
    border-radius: 15px;
    height: 32px;
    width: 32px;
    max-width: 30vw;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-minimal:hover{
    background-color: #E8E8E8;
}

.button {
    cursor: pointer;
}

.no-results{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.no-results p{
    font-size: 18px;
    font-weight: 500;
}

.transcript-viewer-header-bar{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.highlight{
    background-color: #fff9b5;
    /* background-color: #0074d2; */
}

.highlighted-post{
    position: relative;
    z-index: 1;
}

.highlighted-post::before {
    content: "";
    position: absolute;
    top: -15px;
    left: -20px;
    right: -20px;
    bottom: -15px;
    background-color: #fff9b58d;
    border-radius: 20px;
    z-index: -1;
}

.back-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #f0f0f0;
    color: #333;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: background-color 0.3s;
}

.back-to-top-button:hover {
    background-color: #e0e0e0;
}

.navbar-header-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-container{
    background-color: #1042c1;
    width: 100%;
}

.navbar-links{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 0;

}

.navbar-links a{
    color: #fff;
    text-decoration: none;
    width: 130px;
    font-size: 14px;
}

.navbar-links a:hover{
    color: #ccc;
}

.qa-button{
    height: 34px;
    font-size: 14px;
}

.question-input-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.closed{
    display: none;
    padding: 0px;
}

.question-input-container input{
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    padding: 5px;
    padding-left: 10px;
}

.question-input-container input:focus{
    outline: none;
}

.qa-buttons-below{
    display: flex;
    justify-content: right;
    align-items: center;
    gap: 5px;
}

.question-answer-container{
    padding-bottom: 20px;
}

.post-rating-container{
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    gap: 5px;
}

.post-rating{
    font-size: 14px;
    font-weight: 500;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 10px;
    margin: 0;
    cursor: pointer;
}

.post-rating.low{
    background-color: rgba(255, 200, 200, 0.5);
    color: #c20404;
}

.post-rating.low:hover{
    background-color: rgba(255, 200, 200, 0.7);
}

.post-rating.med{
    background-color: rgba(249, 249, 168, 0.63);
    color: #8d8d00;
}

.post-rating.high{
    background-color: rgba(200, 255, 200, 0.5);
    color: #009000;
}

.post-rating-explanation{
    font-size: 12px;
    font-weight: 400;
    color: #555;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 20px;
}

.post-rating-confidence{
    font-size: 12px;
    font-weight: 400;
    color: #555;
    font-style: italic;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 20px;
}

.social-icon {
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1);
}

.social-icons{
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
    margin-right: 10px;
    padding-top: 10px;
}

.email{
    font-size: 14px;
    font-weight: 500;
    padding-top: 8px;
    padding-left: 10px;
}

.sign-up-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 280px;
    padding-right: 5px;
}

@media screen and (min-width: 768px) {
    .sign-up-form {
        width: 350px;
    }
}

.sign-up-form input{
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    padding: 5px;
    padding-left: 10px;
    height: 38px;
    font-size: 16px;
}

.sign-up-form input:focus{
    outline: none;
}

.sign-up-form select{
    border-radius: 10px;
    border: 1px solid #e0e0e0;
    padding: 5px 10px;
    height: 38px;
    display: flex;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: center;
    font-size: 16px;
    color: #000;
    background-color: #fff;
}

.sign-up-form select:focus{
    outline: none;
}

.witg-sign-up p{
    font-size: 14px;
    font-weight: 400;
    color: #555;
    margin-top: 15px;
    margin-bottom: 0px;
    margin-left: 5px;
}

.read-more-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 0.9em;
    padding: 0;
    margin-top: 10px;
}

.read-more-button:hover {
    text-decoration: underline;
}

.post-children{
    margin-left: 10px;
}

.child-post {
    margin-left: 0px;
    padding-left: 0px;
}

.post-children {
    margin-top: 10px;
}


.average-ratings-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.average-ratings-table th,
.average-ratings-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.average-ratings-table th {
    background-color: #f2f2f2;
}

.average-ratings-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.average-ratings-table tr:hover {
    background-color: #f5f5f5;
}


.sign-in-link{
    background-color: inherit;
    border: none;
    color: #4068cd;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.sign-in-link:hover{
    text-decoration: underline;
}

.button-square{
    border-radius: 2px;
}


.log-in-button{
    width: 100%;
}

.button-blue{
    background-color: #1042c1;
    color: #fff;
    border: none;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
}

.button-blue:hover{
    background-color: #0e39a5;
}

.form-input{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 2px;
}

.form-input input{
    height: 38px;
    font-size: 16px;
    border-radius: 2px;
}

.form-input label{
    padding: 0px;
    margin: 0px;
    color: #555;
}

form {
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding: 0px;
    margin: 0px;
}

.log-out-button{
    width: 200px !important;
    border-radius: 10px;
}

.see-more-button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 20px;
    color: #333;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin: 20px auto;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
    display: block;
    outline: none;
  }
  
  .see-more-button:hover {
    background-color: #e0e0e0;
  }
  
  .see-more-button:focus {
    outline: none;
  }
  
  .watchlist-button {
    border: 2px solid #1042c1;
    padding: 8px 15px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: all 0.3s;
    font-weight: bold;
    font-size: 14px;
  }
  
  .watchlist-button:not(.on-watchlist) {
    background-color: white;
    color: #1042c1;
  }
  
  .watchlist-button.on-watchlist {
    background-color: #1042c1;
    color: white;
  }
  
  .watchlist-button:hover {
    opacity: 0.8;
  }
  
  .watchlist-button svg {
    font-size: 18px;
  }
  
  .company-description {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .company-description h3 {
    margin-top: 0;
    color: #333;
    font-size: 24px;
  }
  
  .read-more-link {
    display: inline-block;
    margin-top: 10px;
    color: #1042c1;
    text-decoration: none;
    font-weight: bold;
  }
  
  .read-more-link:hover {
    text-decoration: underline;
  }
  
  .product-section {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-section h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
    border-bottom: 2px solid #1042c1;
    margin-top: 20px;
    padding: 0px;
}

.product-item {
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.product-name {
    font-size: 20px;
    color: #1042c1;
    cursor: pointer;
    margin-bottom: 10px;
}

.product-description {
    font-size: 14px;
    color: #666;
    margin-bottom: 15px;
}

.product-charts {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;
}

.product-charts > div {
    flex: 1 1 300px;
    min-width: 300px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.product-charts h4 {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
}

.post-display {
    max-width: 800px;
    margin: 0 auto;
    padding: 0px;
    font-family: Arial, sans-serif;
    margin: 0 auto; /* Center the div horizontally */
    overflow-wrap: break-word;
    min-width: 350px;
}

.post-display h3{
    font-size: 18px;
}

  
  .post-wrapper {
    background-color: #ffffff;
    border: 1px solid #e1e4e8;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    overflow: hidden;
  }
  
  .post {
    padding: 12px;
    padding-left: 2px;
  }
  
  .post-title {
    font-size: 16px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
  }
  
  .post-meta {
    font-size: 12px;
    color: #666;
  }
  
  .post-container {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    margin-bottom: 12px;
  }
  
  .post-details {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 12px;
    font-size: 13px;
  }
  
  .post-context-link {
    color: #0366d6;
    text-decoration: none;
    cursor: pointer;
  }
  
  .post-context-link:hover {
    text-decoration: underline;
  }
  
  .child-posts {
    margin-top: 20px;
    padding-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  .post.collapsed {
    padding: 4px 0;
    min-height: 28px;
}

.collapsed-preview {
    display: flex;
    align-items: center;
    gap: 12px;
    opacity: 0.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.collapsed-date {
    padding-left: 10px;
}

.collapsed-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: italic;
    font-size: 12px;
}
  
  .nested-post {
    border: none;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  
  button.post-context-link {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    color: #0366d6;
    cursor: pointer;
  }
  
  button.post-context-link:hover {
    text-decoration: underline;
  }
  
  .no-results {
    text-align: center;
    color: #666;
    font-style: italic;
    padding: 20px;
  }
  
  .post-actions {
    display: flex;
    gap: 8px;
    margin-top: 8px;
  }
  
  .post-action-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    font-size: 12px;
  }
  
  .post-action-button:hover {
    color: #0366d6;
  }
  
  .post-action-button svg {
    margin-right: 4px;
  }

  .thematic-search-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.thematic-search-guide {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
}

.thematic-search-guide ol {
    margin-left: 20px;
}

.thematic-search-form {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.theme-select,
.topic-input,
.search-button {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.theme-select {
    flex: 2;
}

.topic-input {
    flex: 3;
}

.search-button {
    flex: 1;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.search-button:hover {
    background-color: #0056b3;
}

.loading {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}

.manager-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    font-family: 'Arial', sans-serif;
    color: #333;
    background-color: #f9f9f9;
}

.manager-header {
    text-align: center;
    margin-bottom: 3rem;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.manager-name {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
}

.manager-current-role {
    font-size: 1.5rem;
    color: #7f8c8d;
    font-weight: normal;
}

.section-title {
    font-size: 1.8rem;
    margin-bottom: 2rem;
    color: #2c3e50;
    text-align: center;
}

.timeline {
    position: relative;
    padding: 2rem 0;
}

.timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-color: #e0e0e0;
}

.timeline-item {
    margin-bottom: 2rem;
    position: relative;
}

.timeline-marker {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #3498db;
    border: 2px solid #fff;
    box-shadow: 0 0 0 4px #e0e0e0;
}

.timeline-content {
    width: calc(50% - 30px);
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.timeline-item:nth-child(odd) .timeline-content {
    margin-left: auto;
}

.role-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
}

.company-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #34495e;
}

.date-range {
    font-style: italic;
    color: #7f8c8d;
}

@media (max-width: 768px) {
    .timeline::before {
        left: 30px;
    }
    
    .timeline-marker {
        left: 30px;
    }
    
    .timeline-content {
        width: calc(100% - 80px);
        margin-left: 80px !important;
    }
}

.management-team {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px;
    margin-top: 24px;
  }
  
  .management-team__title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 16px;
  }
  
  .management-team__list {
    list-style-type: none;
    padding: 0;
  }
  
  .management-team__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #f8f8f8;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .management-team__role {
    font-weight: 500;
    color: #555;
  }
  
  .management-team__manager-link {
    color: #0066cc;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .management-team__manager-link:hover {
    color: #004499;
    text-decoration: underline;
  }
  
  @media (max-width: 600px) {
    .management-team__item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .management-team__role {
      margin-bottom: 4px;
    }
  }

  
.video-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .video-thumbnail {
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .video-thumbnail:hover {
    transform: scale(1.05);
  }
  
  .video-thumbnail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .video-thumbnail h3 {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }













  /* Buttons */
  .btn {
    border: none;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    height: 38px;
  }

  .square {
    border-radius: 5px;
  }

  .white {
    background-color: #fff;
    color: #1042c1;
    width: 120px;
  }
  
  .white:hover{
    background-color: #ccc;
  }

  .blue{
    background-color: #1042c1;
    color: #fff;
  }

  .blue:hover{
    background-color: #002fa3;
  }

  .white-outline{
    background-color: transparent;
    color: #fff;
    border: 2px solid #fff;
  }

  .white-outline:hover{
    background-color: rgba(255, 255, 255, 0.2);
    /* color: #1042c1; */
  }
  
/* Remove spaces between class selectors */
.btn.wide {
    width: 300px;
}

.btn.narrow {
    width: 120px;
}

.btn.tall {
    height: 50px;
}

.btn.regular {
    height: 40px;
}

  /* /* For mobile screens */
  /* @media screen and (max-width: 767px) {

    button {
        width: 100% !important;
        max-width: 100%;
    }
  }  */



  /* Container Sizes */

  
.standard-width{
    max-width: calc(min(800px, 90%));
    width: 100%;
}

.wide-width{
    max-width: calc(min(1200px, 90%));
}

.mobile-width{
    width: 85%;
}

.fundamentals-section {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin: 20px 0;
}

.fundamentals-charts {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (max-width: 768px) {
    .fundamentals-charts {
        gap: 10px;
    }
}

.fundamentals-table-container {
    overflow-x: auto;
    margin: 0px 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.fundamentals-table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px;
}

.fundamentals-table th,
.fundamentals-table td {
    padding: 12px 20px;  /* Increased padding */
    text-align: right;
    border-bottom: 1px solid #eee;
    white-space: nowrap;  /* Prevent wrapping */
}

.fundamentals-table th:first-child,
.fundamentals-table td:first-child {
    position: sticky;  /* Make first column sticky */
    left: 0;
    background: white;
    z-index: 1;
    text-align: left;
    padding-left: 24px;  /* More padding on the left */
    font-weight: 500;
    border-right: 1px solid #eee;  /* Add separator */
}

.fundamentals-table th {
    background-color: #f8f9fa;
    font-weight: 600;
    color: #333;
    padding-top: 16px;  /* More padding for header */
    padding-bottom: 16px;
}

.fundamentals-table th:first-child {
    background-color: #f8f9fa;  /* Match header background */
}

.fundamentals-table tr:hover {
    background-color: #f8f9fa;
}

.fundamentals-table tr:hover td:first-child {
    background-color: #f8f9fa;  /* Match hover background */
}

.fundamentals-table tr:last-child td {
    border-bottom: none;
}

@media (max-width: 768px) {
    .fundamentals-table th,
    .fundamentals-table td {
        padding: 12px 16px;
        font-size: 13px;
    }
}

.fundamentals-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
}

.fundamentals-header h3 {
    margin: 0;
}

.MuiToggleButtonGroup-root {
    background-color: white;
}

.MuiToggleButton-root {
    text-transform: none !important;
    font-size: 14px !important;
}

.MuiToggleButton-root.Mui-selected {
    background-color: #1042c1 !important;
    color: white !important;
}

.fundamentals-controls {
    margin-left: 60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.time-range-toggle {
    margin-right: 10px;
}

/* Make the toggle buttons more compact */
.MuiToggleButton-root {
    padding: 5px 10px !important;
    min-width: 40px;
}

/* Ensure buttons are same height */
.MuiToggleButtonGroup-root {
    height: 32px;
}