.nps-metrics-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.nps-metric-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.nps-metric-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.metric-period {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
}

.metric-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.metric-label {
    color: #666;
    font-size: 0.9em;
}

.metric-value {
    font-weight: bold;
    font-size: 1.1em;
}

.metric-value.positive {
    color: #28a745;
}

.metric-value.neutral {
    color: #ffc107;
}

.metric-value.negative {
    color: #dc3545;
}

.sample-size {
    margin-top: 15px;
    font-size: 0.8em;
    color: #888;
}

.nps-header {
    margin: 20px 0;
    color: #333;
    font-size: 1.3em;
}

.settings-group {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.settings-group h4 {
    margin-top: 0;
    margin-bottom: 15px;
    color: #333;
}

.settings-group label {
    display: block;
    margin-bottom: 10px;
    color: #555;
}

.settings-group input {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 4px 8px;
}

.fundamentals-controls {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
}

/* Custom Toggle Styles */
.custom-toggle {
    display: flex;
    align-items: center;
    gap: 8px;
}

.custom-toggle.small {
    font-size: 0.9em;
}

.toggle-label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.toggle-label input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;
}

.toggle-slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .toggle-slider {
    background-color: #2f47b8;
}

input:checked + .toggle-slider:before {
    transform: translateX(20px);
}

.toggle-text {
    color: #555;
    user-select: none;
}

/* Product Selector Styles */
.product-selector {
    min-width: 200px;
}

/* Time Range Selector Styles */
.time-range-selector {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .nps-metrics-container {
        grid-template-columns: 1fr;
    }

    .fundamentals-controls {
        flex-direction: column;
        align-items: stretch;
    }

    .settings-group {
        grid-template-columns: 1fr;
    }
} 