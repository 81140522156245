/* CSS Variables for colors */
:root {
  /* Colors */
  --primary: #1042c1;
  --primary-dark: #08103d;
  --primary-light: #4068cd;
  --white: #ffffff;
  --gray-100: #f6f6f6;
  --gray-200: #e6e6e6;
  --gray-300: #ccc;
  --gray-400: #b5b5b5;
  --gray-600: #666;
  --gray-700: #555;
  --gray-900: #1f1f1f;
  --danger: #c20404;
  --danger-dark: #960202;
  
  /* Spacing */
  --spacing-xs: 5px;
  --spacing-sm: 10px;
  --spacing-md: 15px;
  --spacing-lg: 20px;
  --spacing-xl: 30px;
  
  /* Border Radius */
  --radius-sm: 2px;
  --radius-md: 5px;
  --radius-lg: 10px;
  --radius-full: 20px;
  
  /* Font Sizes */
  --font-size-xs: 12px;
  --font-size-sm: 14px;
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-xl: 24px;
  --font-size-2xl: 30px;
  --font-size-3xl: 36px;
  --font-size-4xl: 48px;

  /* Font Weights */
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;

  /* Line Heights */
  --line-height-tight: 1.2;
  --line-height-normal: 1.5;
  --line-height-relaxed: 1.75;

  /* Card Styles */
  --card-bg: white;
  --card-border: 1px solid #e0e0e0;
  --card-radius: 12px;
  --card-shadow: 0 2px 8px rgba(0,0,0,0.08);
  --card-padding-sm: 16px;
  --card-padding-md: 24px;
  --card-padding-lg: 32px;

  /* Add font family variable */
  --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
}

body {
    overflow-y: scroll; /* Ensures scrollbar is always visible */
    background-color: #fcfcfc !important;
}

/* Base Button Styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-sm);
  font-weight: 600;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  gap: var(--spacing-xs);
  padding: 0 var(--spacing-md);
  font-family: var(--font-family);
}

.btn-bold {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-sm);
}

/* Button Sizes */
.btn-sm {
  height: 32px;
  font-size: var(--font-size-xs);
  padding: 0 var(--spacing-sm);
}

.btn-md {
  height: 38px;
}

.btn-lg {
  height: 48px;
  font-size: var(--font-size-base);
  padding: 0 var(--spacing-lg);
}

/* Button Widths */
.btn-narrow {
  width: 120px;
}

.btn-wide {
  width: 200px;
}

.btn-very-wide {
  width: 300px;
}

.btn-full {
  width: 100%;
}

@media (max-width: 768px) {
  .btn-wide {
    width: 100%;
  }

  .btn-very-wide {
    width: 100%;
  }
}


/* Button Variants */
.btn-primary {
  background-color: var(--primary);
  color: var(--white);
  border: 2px solid var(--primary);
}

.btn-primary:hover {
  background-color: #0935a0;
  border-color: #0935a0;
}

.btn-outline {
  background-color: transparent;
  border: 2px solid var(--primary);
  color: var(--primary);
}

.btn-outline:hover {
  background-color: var(--primary);
  color: var(--white);
}

.btn-white {
  background-color: var(--white);
  color: #1042c1;
  border: none;
}

.btn-white:hover {
  background-color: var(--gray-200);
}

.btn-white-outline {
  background-color: transparent;
  border: 2px solid var(--white);
  color: var(--white);
}

.btn-white-outline:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Button Shapes */
.btn-square {
  border-radius: var(--radius-md);
}

.btn-rounded {
  border-radius: var(--radius-full);
}

/* Icon Button Styles */
.btn-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: var(--radius-full);
  border: none;
  background-color: var(--gray-100);
  color: var(--gray-700);
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-no-bg{
  background-color: transparent;
}

.btn-icon:hover {
  background-color: var(--gray-200) !important;
}

/* Icon Button Sizes */
.btn-icon-sm {
  width: 32px;
  height: 32px;
}

.btn-icon-md {
  width: 38px;
  height: 38px;
}

.btn-icon-lg {
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

/* Icon Button Variants */
.btn-icon-primary {
  background-color: var(--primary);
  color: var(--white);
}

.btn-icon-primary:hover {
    background-color: #0935a0;
}
  

.btn-icon-transparent {
  background-color: transparent;
  color: var(--gray-700);
}

.btn-icon-transparent:hover {
  background-color: rgba(255, 255, 255, 0.2);
}




.btn-icon-outline {
  background-color: transparent;
  border: 2px solid var(--gray-300);
  color: var(--gray-700);
}

.btn-icon-outline:hover {
  border-color: var(--gray-400);
  background-color: var(--gray-100);
}

/* Disabled States */
.btn:disabled,
.btn-icon:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

/* Icon Sizes */
.btn-icon-sm svg {
  font-size: 16px;
}

.btn-icon-md svg {
  font-size: 20px;
}

.btn-icon-lg svg {
  font-size: 24px;
}

/* Mobile Responsiveness */
@media screen and (max-width: 767px) {
  .btn-full-mobile {
    width: 100%;
  }
  
  .btn {
    height: 40px;
  }
  
  .btn-lg {
    height: 50px;
  }
}

/* Layout Patterns */
.split-layout {
  display: flex;
  gap: var(--spacing-lg);
}

.content-container {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* Post Cards */
.post-card {
  background-color: var(--white);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.post-text {
    color: black;
    font-size: 14px;
    overflow-wrap: break-word;
    max-width: 100%;
    padding-bottom: 0px;
}

.post-text .md {
    display: flex;
    flex-direction: column;
}
  
.post-text p {
    font-size: 14px;
    overflow-wrap: break-word;
    margin-bottom: 1em; /* Add space between paragraphs */
}

.post-text p:last-child {
    margin-bottom: 0; /* Remove margin from last paragraph */
}

.post-text * {
    font-size: min(1em, 18px) !important;
    line-height: 1.5 !important;
    margin: 0px;
    padding: 0px;
}

/* Add these new rules */
.post-text ul, .post-text ol {
    padding-left: 20px; /* Add some left padding for lists */
    display: flex;
    flex-direction: column;
}

.post-text li {
    margin-bottom: 0.5em; /* Add some space between list items */
    list-style-type: disc; /* Add an actual bullet point */
}

.post-text ol {
    list-style-type: decimal; /* Add numbering for ordered lists */
}

.post-text {
    white-space: pre-wrap; /* Preserve line breaks */
}

.post-text code {
    white-space: pre-wrap;       /* CSS 3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
    display: inline-block;
    max-width: 100%;
    overflow-x: auto;
    padding: 2px 4px;
    background-color: #f6f8fa;
    border-radius: 3px;
    font-size: 85%;
    font-family: monospace;
}

.post-text br {
    content: "";
    display: block;
    margin-bottom: 0.5em; /* Adjust this value to control space between lines */
}

.post-title {
  font-size: var(--font-size-lg);
  font-weight: 600;
  color: var(--gray-900);
}

.post-actions {
  display: flex;
  gap: var(--spacing-xs);
}

.post-content {
  margin-bottom: var(--spacing-md);
}

.post-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-sm);
  color: var(--gray-600);
}

.post-meta {
  font-style: italic;
}

.post-tags {
  display: flex;
  gap: var(--spacing-xs);
}

.tag {
  background-color: var(--gray-100);
  padding: 2px var(--spacing-sm);
  border-radius: var(--radius-full);
  font-size: var(--font-size-xs);
}

/* Modal Patterns */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: var(--white);
  border-radius: var(--radius-lg);
  padding: var(--spacing-lg);
  width: 100%;
  max-width: 500px;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-md);
}

.modal-title {
  font-size: var(--font-size-xl);
  font-weight: 600;
}

.modal-content {
  margin-bottom: var(--spacing-lg);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-sm);
}

/* Navigation
/* .header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--spacing-sm) var(--spacing-lg);
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-200);
}

.header-nav {
  display: flex;
  gap: var(--spacing-md);
}

.nav-link {
  color: var(--gray-700);
  padding: var(--spacing-xs) var(--spacing-sm);
  border-radius: var(--radius-md);
  font-weight: 500;
  transition: all 0.2s ease;
  border: none;
  background: none;
  cursor: pointer;
}

.nav-link:hover {
  background-color: var(--gray-100);
}

.nav-link.active {
  color: var(--primary);
  background-color: var(--gray-100);
} */ 

/* Responsive Patterns */
@media (max-width: 768px) {
  .split-layout {
    flex-direction: column;
  }

  .modal {
    margin: var(--spacing-md);
    max-width: calc(100% - var(--spacing-lg));
  }

  .header-nav {
    display: none;
  }
}

/* Form Groups */
.form-group {
  margin-bottom: var(--spacing-md);
}

.form-group-inline {
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);
}

/* Search Bar Pattern */
.search-bar {
  display: flex;
  gap: var(--spacing-xs);
  background-color: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-full);
  padding: var(--spacing-xs);
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  padding: var(--spacing-xs) var(--spacing-sm);
}

/* Progress Indicators */
.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-xl) 0;
}

/* Empty States */
.empty-state {
  text-align: center;
  padding: var(--spacing-xl);
  color: var(--gray-600);
}

.empty-state-icon {
  font-size: 48px;
  color: var(--gray-400);
  margin-bottom: var(--spacing-md);
}

/* Add these utility classes */
.bg-white {
  background-color: var(--white);
}

.bg-gray-50 {
  background-color: var(--gray-50);
}

.bg-gray-100 {
  background-color: var(--gray-100);
}

.rounded-lg {
  border-radius: var(--radius-lg);
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.min-h-screen {
  min-height: 100vh;
}

.py-xl {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}

.pb-md {
  padding-bottom: var(--spacing-md);
}

.border-b {
  border-bottom: 1px solid var(--gray-200);
} 

/* Add these button variants */

/* Standard Gray Button */
.btn-gray {
  background-color: var(--gray-200); /* #E6E6E6 */
  color: var(--gray-900); /* #1F1F1F */
  border: 2px solid var(--gray-200);
}

.btn-gray:hover {
  background-color: var(--gray-300); /* #ccc */
  border-color: var(--gray-300);
}

/* Gray Outline Button */
.btn-gray-outline {
  background-color: transparent;
  border: 2px solid var(--gray-200);
  color: var(--gray-900);
}

.btn-gray-outline:hover {
  background-color: var(--gray-200);
  color: var(--gray-900);
}

/* Gray Icon Button */
.btn-icon-gray {
  background-color: var(--gray-200);
  color: var(--gray-900);
}

.btn-icon-gray:hover {
  background-color: var(--gray-300);
} 

/* Typography Styles */
.text-xs {
  font-size: var(--font-size-xs);
  line-height: var(--line-height-normal);
}

.text-sm {
  font-size: var(--font-size-sm);
  line-height: var(--line-height-normal);
}

.text-base {
  font-size: var(--font-size-base);
  line-height: var(--line-height-relaxed);
}

.text-lg {
  font-size: var(--font-size-lg);
  line-height: var(--line-height-relaxed);
}

.text-xl {
  font-size: var(--font-size-xl);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-semibold);
}

.text-2xl {
  font-size: var(--font-size-2xl);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-semibold);
}

.text-3xl {
  font-size: var(--font-size-3xl);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-bold);
}

.text-4xl {
  font-size: var(--font-size-4xl);
  line-height: var(--line-height-tight);
  font-weight: var(--font-weight-bold);
}

/* Font Weights */
.font-normal {
  font-weight: var(--font-weight-normal);
}

.font-medium {
  font-weight: var(--font-weight-medium);
}

.font-semibold {
  font-weight: var(--font-weight-semibold);
}

.font-bold {
  font-weight: var(--font-weight-bold);
}

/* Text Colors */
.text-primary {
  color: var(--primary);
}

.text-gray-600 {
  color: var(--gray-600);
}

.text-gray-700 {
  color: var(--gray-700);
}

.text-gray-900 {
  color: var(--gray-900);
}

/* Text Alignment */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/* Text Styles */
.italic {
  font-style: italic;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

/* Line Heights */
.leading-tight {
  line-height: var(--line-height-tight);
}

.leading-normal {
  line-height: var(--line-height-normal);
}

.leading-relaxed {
  line-height: var(--line-height-relaxed);
} 

/* After the existing modal patterns section, update/add these styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-close-button {
  position: absolute;
  top: var(--spacing-md);
  right: var(--spacing-md);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--gray-600);
  padding: var(--spacing-xs);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-full);
  transition: all 0.2s ease;
}

.modal-close-button:hover {
  background-color: var(--gray-100);
  color: var(--gray-900);
} 

/* Add form specific styles */
.form-container {
  width: 100%;
}

.form-input {
  margin-bottom: var(--spacing-md);
}

.form-input label {
  display: block;
  font-size: var(--font-size-sm);
  color: var(--gray-700);
  margin-bottom: var(--spacing-xs);
}

.form-input input {
  width: 100%;
  padding: var(--spacing-sm);
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  font-size: var(--font-size-base);
}

.form-input input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(16, 66, 193, 0.1);
}

.sign-in-link {
  display: block;
  width: 100%;
  text-align: center;
  color: var(--primary);
  background: none;
  border: none;
  padding: var(--spacing-md) 0;
  cursor: pointer;
  font-size: var(--font-size-sm);
}

.sign-in-link:hover {
  text-decoration: underline;
}

.log-in-button {
  width: 100%;
  margin-top: var(--spacing-sm);
}

.logged-in-container {
  text-align: center;
  margin-bottom: var(--spacing-lg);
}

.get-started-button {
  width: 100%;
} 

/* Update the z-index values to ensure proper stacking */
.header-bar-container {
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100; /* Lower this from 1000 to 100 */
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Keep this at 1000 */
}
/* Add this new Card Components section */
.card {
  background: var(--card-bg);
  border-radius: var(--card-radius);
  border: var(--card-border);
  box-shadow: var(--card-shadow);
}

.card-sm {
  padding: var(--card-padding-sm);
}

.card-md {
  padding: var(--card-padding-md);
}

.card-lg {
  padding: var(--card-padding-lg);
}

.card-header {
  margin-bottom: var(--spacing-md);
}

.card-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--gray-900);
  margin: 0;
}

.card-subtitle {
  font-size: var(--font-size-sm);
  color: var(--gray-600);
  margin-top: var(--spacing-xs);
}

.card-content {
  margin-bottom: var(--spacing-md);
}

.card-footer {
  margin-top: var(--spacing-md);
  padding-top: var(--spacing-md);
  border-top: 1px solid var(--gray-200);
} 


.summary-container{
    background-color: #f4f4f4;
    padding: 30px;
    margin-top: 15px;
    border: 1px solid #e0e0e0;
}

.summary-container h4{
    margin: 0;
    padding: 0;
}


/* Content Card Layout */
.content-section {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.content-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xs);
}

.content-title {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-medium);
  color: #333;
  margin: 0;
}

.content-subtitle {
  font-size: var(--font-size-sm);
  color: var(--gray-600);
  margin: 0;
} 

.btn-icon.success {
  background-color: rgba(76, 175, 80, 0.1);
} 

/* Add these new modal form styles */
.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
}

.form-input {
  position: relative;
  width: 100%;
}

.form-input input {
  width: 100%;
  height: 42px;
  padding: var(--spacing-sm) var(--spacing-md);
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  font-size: var(--font-size-base);
  transition: all 0.2s ease;
}

.form-input input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(16, 66, 193, 0.1);
}

.form-input input::placeholder {
  color: var(--gray-400);
}

/* Update modal styles */
.modal-container {
  background-color: var(--white);
  border-radius: var(--radius-lg);
  padding: var(--spacing-xl);
  position: relative;
}

.modal-header {
  margin-bottom: var(--spacing-lg);
  text-align: center;
}

.modal-title {
  font-size: var(--font-size-xl);
  font-weight: var(--font-weight-semibold);
  color: var(--gray-900);
  margin: 0;
} 

/* Add these custom dropdown styles before the modal-container styles */

/* Custom Dropdown Styles */
.custom-dropdown {
  position: relative;
  display: inline-block;
  min-width: 200px;
}

.dropdown-button {
  width: 100%;
  padding: 8px 12px;
  background: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: var(--font-size-sm);
  color: var(--gray-900);
  transition: all 0.2s ease;
}

.dropdown-button:hover {
  border-color: var(--gray-400);
}

.dropdown-button svg {
  font-size: 20px;
  color: var(--gray-600);
  transition: transform 0.2s ease;
}

.dropdown-button.open svg {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: var(--white);
  border: 1px solid var(--gray-300);
  border-radius: var(--radius-md);
  box-shadow: var(--card-shadow);
  z-index: 10;
  max-height: 250px;
  overflow-y: auto;
}

.dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  font-size: var(--font-size-sm);
  color: var(--gray-900);
  transition: all 0.15s ease;
  background: transparent;
  border: none;
  width: 100%;
  text-align: left;
}

.dropdown-item:hover {
  background: var(--gray-100);
}

.dropdown-item.selected {
  background: var(--gray-100);
  font-weight: var(--font-weight-medium);
}

/* Preference Bar Visualization Styles */
.preference-stats {
  width: 100%;
  margin: var(--spacing-md) 0;
}

.preference-bar {
  width: 100%;
  height: 24px;
  background: var(--gray-200);
  border-radius: var(--radius-full);
  overflow: hidden;
  display: flex;
  margin-bottom: var(--spacing-md);
  cursor: pointer;
  transition: all 0.2s ease;
}

.preference-segment {
  height: 100%;
  transition: all 0.2s ease;
  position: relative;
}

.preference-segment:hover {
  filter: brightness(0.9);
}

.preference-segment.prefer-product {
  background-color: #4caf50;
}

.preference-segment.neutral {
  background-color: #9e9e9e;
}

.preference-segment.prefer-competitor {
  background-color: #f44336;
}

.preference-labels {
  display: flex;
  width: 100%;
  margin-top: var(--spacing-xs);
}

.preference-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  padding: 0 var(--spacing-xs);
}

.preference-percentage {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--gray-900);
  margin-bottom: 2px;
}

.preference-description {
  font-size: var(--font-size-xs);
  color: var(--gray-600);
  line-height: 1.2;
  max-width: 100%;
  overflow-wrap: break-word;
}

/* Tooltip styles for the preference bar */
.preference-segment::before {
  content: attr(title);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: var(--radius-sm);
  font-size: var(--font-size-xs);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
}

.preference-segment:hover::before {
  opacity: 1;
  visibility: visible;
}

/* Market overview specific styles */
.market-overview-card .preference-bar {
  height: 32px;
}

.market-overview-card .preference-percentage {
  font-size: var(--font-size-xl);
}

.market-overview-card .preference-description {
  font-size: var(--font-size-sm);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .preference-bar {
    height: 20px;
  }
  
  .preference-percentage {
    font-size: var(--font-size-base);
  }
  
  .preference-description {
    font-size: var(--font-size-xs);
  }
  
  .market-overview-card .preference-bar {
    height: 24px;
  }
  
  .market-overview-card .preference-percentage {
    font-size: var(--font-size-lg);
  }
} 