.article {
  width: 100%;
  font-family: "Inter", sans-serif;
  color: #1e293b;
  background-color: #fff;
}

.article-hero {
  width: 100%;
  height: 400px;
  background: #f8fafc;
  overflow: hidden;
  position: relative;
}

.article-hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-hero {
  display: none;
}

.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 3rem 2rem;
}

.article-header {
  margin-bottom: 3rem;
  text-align: center;
}

.article-title {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  color: #0f172a;
}

.article-subtitle {
  font-size: 1.5rem;
  color: #64748b;
  line-height: 1.6;
}

.article-content {
  line-height: 1.8;
  color: #334155;
  font-size: 1rem;
}

.article-content h1 {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 3rem 0 1.5rem;
  color: #0f172a;
}

.article-content h2 {
  font-size: 2rem;
  font-weight: 700;
  margin: 2.5rem 0 1.25rem;
  color: #0f172a;
}

.article-content hr {
  margin: 2rem 0;
  border: none;
  border-top: 1px solid #e2e8f0;
}

.article-content strong {
  color: #1e293b;
  font-weight: 600;
}

.article-content ul {
  list-style-type: none;
  padding-left: 1.5rem;
  font-size: 1rem;
}

.article-content ul li {
  position: relative;
  margin-bottom: 1rem;
}

.article-content ul li:before {
  content: "•";
  color: #1042c1;
  position: absolute;
  left: -1.5rem;
  font-size: 1rem;
  line-height: 1.8;
}

.article-content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem 0 1rem;
  color: #0f172a;
}

.article-content p {
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

.article-content ul,
.article-content ol {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
  font-size: 1rem;
}

.article-content li {
  margin-bottom: 0.75rem;
  font-size: 1rem;
}

.article-content blockquote {
  margin: 2rem 0;
  padding: 0.25rem 2rem;
  border-left: 4px solid #1042c1;
  background: #f8fafc;
  font-style: italic;
  font-size: 1rem;
  color: #475569;
}

.article-image-container {
  margin: 2.5rem -2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 0 auto;
}

.article-image-caption {
  text-align: center;
  color: #64748b;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.article-cta {
  text-align: left;
}

/* Mobile styles */
@media (max-width: 768px) {
  .desktop-hero {
    display: none;
  }

  .mobile-hero {
    display: block;
  }

  .article-hero {
    height: 300px;
  }

  .article-container {
    padding: 2rem 1.5rem;
  }

  .article-title {
    font-size: 2.25rem;
  }

  .article-subtitle {
    font-size: 1.25rem;
  }

  .article-content {
    font-size: 1rem;
  }

  .article-image-container {
    margin: 2rem -1.5rem;
    padding: 1.5rem;
  }

  .article-content h1 {
    font-size: 2rem;
  }

  .article-content h2 {
    font-size: 1.75rem;
  }

  .article-content h3 {
    font-size: 1.375rem;
  }

  .article-content blockquote {
    font-size: 1rem;
    padding: 1.25rem 1.5rem;
  }

  .article-cta {
    text-align: center;
  }
} 

.desktop-image {
  display: block;
}

.mobile-image {
  display: none;
}

@media (max-width: 768px) {
  .desktop-image {
    display: none;
  }
  
  .mobile-image {
    display: block;
  }
} 

.article-content ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
}

.article-content ol li {
  position: relative;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
} 