.transcript-display {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    background: white;
    border-radius: var(--card-radius);
    border: var(--card-border);
    box-shadow: var(--card-shadow);
    padding: var(--card-padding-md);
}

.transcript-header {
    border-bottom: 1px solid var(--gray-200);
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.quarter-selector {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.quarter-button {
    padding: 0.5rem 1rem;
    border: 1px solid var(--gray-300);
    border-radius: var(--radius-md);
    background: white;
    color: var(--gray-700);
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-medium);
}

.quarter-button:hover {
    background: var(--gray-100);
    border-color: var(--gray-400);
}

.quarter-button.active {
    background: var(--primary);
    color: white;
    border-color: var(--primary);
}

.transcript-content {
    min-height: 400px;
}

.transcript-loading,
.transcript-message,
.transcript-error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;
    color: var(--gray-600);
}

.transcript-error {
    color: var(--danger);
}

.transcript-text {
    font-size: var(--font-size-base);
    line-height: var(--line-height-relaxed);
    color: var(--gray-900);
}

.transcript-text p {
    margin-bottom: 1rem;
}

.transcript-text p:empty {
    margin-bottom: 2rem;
}

.transcript-info {
    margin-bottom: 1rem;
}

.transcript-note {
    font-size: var(--font-size-sm);
    color: var(--gray-600);
    margin: 0;
    padding: 0.5rem;
    background-color: var(--gray-100);
    border-radius: var(--radius-md);
    display: inline-block;
}

.transcript-controls {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.transcript-search {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid var(--gray-300);
    border-radius: var(--radius-md);
    padding: 0.5rem;
    gap: 0.5rem;
}

.search-icon {
    color: var(--gray-600);
    font-size: 20px !important;
}

.search-input {
    flex: 1;
    border: none;
    outline: none;
    font-size: var(--font-size-base);
    color: var(--gray-900);
    min-width: 200px;
}

.search-input::placeholder {
    color: var(--gray-500);
}

.search-count {
    padding: 0.25rem 0.5rem;
    background: var(--gray-100);
    border-radius: var(--radius-sm);
    font-size: var(--font-size-sm);
    color: var(--gray-600);
}

.search-result {
    padding: 0.5rem;
    border-radius: var(--radius-sm);
    background: var(--gray-50);
    margin-bottom: 0.5rem;
}

mark {
    background-color: #fff3cd;
    padding: 0.1em 0;
    border-radius: 2px;
}

.transcript-container {
    display: flex;
    gap: 1.5rem;
    height: 100%;
}

.transcript-main {
    flex: 1;
    min-width: 0; /* Prevents flex item from overflowing */
    display: flex;
    flex-direction: column;
}

.search-results-panel {
    width: 300px;
    border-left: 1px solid var(--gray-200);
    display: flex;
    flex-direction: column;
    height: 600px;
}

.search-results-header {
    padding: 1rem;
    font-weight: var(--font-weight-medium);
    border-bottom: 1px solid var(--gray-200);
    background: var(--gray-50);
}

.search-results-list {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
}

.search-result-item {
    padding: 0.75rem;
    border: 1px solid var(--gray-200);
    border-radius: var(--radius-md);
    margin-bottom: 0.75rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.search-result-item:hover {
    background: var(--gray-50);
    border-color: var(--gray-300);
}

.search-result-item.selected {
    border-color: var(--primary);
    background: var(--gray-50);
}

.search-result-context {
    font-size: var(--font-size-sm);
    line-height: 1.5;
    color: var(--gray-900);
}

.transcript-line {
    padding: 0.25rem 0;
}

.transcript-line.selected-line {
    background-color: var(--gray-100);
    padding: 0.5rem;
    border-radius: var(--radius-sm);
}

.transcript-content {
    flex: 1;
    overflow-y: auto;
    padding-right: 1rem;
    height: 600px;
    position: relative;
}

.text-highlight {
    animation: highlight-fade 2s ease-out;
    pointer-events: none;
    z-index: 1;
}

@keyframes highlight-fade {
    0% {
        background-color: var(--primary);
        opacity: 0.2;
    }
    100% {
        background-color: transparent;
        opacity: 0;
    }
}

.transcript-line {
    padding: 0.5rem;
    border-radius: var(--radius-sm);
    transition: background-color 0.2s ease;
}

.transcript-line.selected-line {
    background-color: var(--gray-100);
} 