.fundamentals-charts {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
}

.fundamentals-header {
  margin-bottom: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fundamentals-header h3 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--gray-900);
  margin: 0;
}

.fundamentals-table-container {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
}

.chart-container {
  background: var(--card-bg);
  border-radius: var(--card-radius);
  border: var(--card-border);
  box-shadow: var(--card-shadow);
  padding: var(--card-padding-md);
}

.fixed-column {
  position: sticky;
  left: 0;
  z-index: 2;
  background: white;
  border-right: 1px solid #e0e0e0;
  min-width: 180px;
}

.fixed-column table {
  width: 100%;
  border-collapse: collapse;
}

.table-scroll {
  overflow-x: auto;
  flex-grow: 1;
}

.table-scroll table {
  border-collapse: collapse;
  width: 100%;
}

/* Common table styles */
.fixed-column table,
.table-scroll table {
  font-size: 14px;
}

.fixed-column th,
.fixed-column td,
.table-scroll th,
.table-scroll td {
  padding: 8px 24px;
  border-bottom: 1px solid #e0e0e0;
  white-space: nowrap;
  line-height: 1.5;
}

.fixed-column td {
  text-align: left;
  color: #333;
  font-weight: 500;
}

.table-scroll td {
  text-align: right;
  color: #333;
}

.fixed-column th,
.table-scroll th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #666;
  text-align: right;
}

.fixed-column th {
  text-align: left;
}

/* Shadow effect */
.fixed-column::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 6px;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0,0,0,0.08), transparent);
} 