.video-transcript-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.video-section {
  margin-bottom: 20px;
}

.transcript-section {
  background: #ffffff;
  padding: 40px;
  border-radius: 8px;
  max-height: 600px;
  overflow-y: auto;
  line-height: 1.6;
  font-size: 1.1em;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.transcript-paragraph {
  margin-bottom: 1.5em;
  color: #333;
}

.transcript-sentence {
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 2px;
}

.transcript-sentence:hover {
  background-color: #f0f0f0;
} 