.filtered-posts-section {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.filtered-posts-close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
}

.filtered-posts-close-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 6px;
    width: 28px;
    height: 28px;
    color: var(--text-secondary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.15s ease;
}

.filtered-posts-close-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.filtered-posts-close-button svg {
    font-size: 16px;
}

.filtered-posts-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    min-height: 36px;
}

.filtered-posts-titles {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.filtered-posts-title {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--text-primary);
    line-height: 1.2;
}

.filtered-posts-subtitle {
    margin: 0;
    font-size: 0.9rem;
    color: var(--text-secondary);
}

/* Sort controls */
.sort-controls {
    display: flex;
    align-items: center;
    height: 100%;
}

.custom-dropdown {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.dropdown-button {
    background: #fff;
    border: 1px solid #ddd;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    color: #666;
    height: 32px;
    transition: all 0.2s ease;
}

.dropdown-button svg {
    font-size: 18px;
}

.dropdown-button:hover {
    border-color: #999;
}

.dropdown-button.open {
    border-color: #999;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 4px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 180px;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 8px 12px;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    font-size: 0.9rem;
    color: #666;
    transition: all 0.2s ease;
}

.dropdown-item:hover {
    background-color: #f5f5f5;
}

.dropdown-item.selected {
    background-color: #f0f0f0;
    font-weight: 500;
}

/* View more button */
.view-more-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.view-more-button {
    background: none;
    border: 1px solid var(--border-color);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    color: var(--text-primary);
    font-size: 0.9rem;
}

.view-more-button:hover {
    background-color: var(--hover-background);
}

/* Post list styles */
.post-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.no-results {
    padding: 24px;
    text-align: center;
    color: #666;
    font-size: 0.9rem;
    background-color: #f9f9f9;
    border-radius: 4px;
    margin: 16px 0;
} 