html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}

.header-container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.landing-navbar{
    margin-top: 15px;
    background-color: #1042c1;
    border-radius: 40px;
    height: 80px;
    padding-left: 20px;
    align-items: center;
    display: flex;
    max-width: 1200px;
    width: calc(min(90vw, 1200px));
    box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.25);
}

.twitter-search-header-container{
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 5px;
    width: fit-content;
}

.scuttlesift-header{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
}

.twitter-search-header-container h1, h4{
    padding: 0;
    margin: 0;
}

.twitter-search-header-container h1 a{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.twitter-search-header-container h4 a{
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    padding: 0;
    margin:0;
}

.try-button{
    background-color: #fff;
    border-radius: 40px;
    height: 40px;
    width: 100px;
    border: none;
    color: #1042c1;
    font-size: 16px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
}

.try-button:hover{
    background-color: #e6e6e6;
}

.landing-navbar-right{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-left: auto;
    margin-right: 20px;
    align-items: center;
}

.landing-navbar-right-button{
    height: 40px;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    background-color: transparent;
}

.landing-navbar-right-buttons{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.side-by-side{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.top-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.body-section {
    width: calc(min(80vw, 1000px));
}

.body-section-left{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 50%;
    gap: 20px;
}

.body-section h1{
    text-align: left;
    font-size: 28px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
}

.body-section h2{
    text-align: left;
    font-size: 36px;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    color: #1042c1;
    text-decoration: underline;
}

.body-section-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.body-section-right img{
    padding-top: 20px;
    width: 95%;
}

.container-full{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

.gray{
    background-color: #f5f5f5;
}

.before-after img{
    width: calc(max(80%, 375px));
    padding: 0px;
}

h3 {
    font-size: 24px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
}

.feature img{
    width: 375px;
    padding: 0px;
}

.feature {
    gap: 20px;
}

.feature h3 {
    text-align: left;
    font-size: 24px;    
}

.title-section{
    width: calc(min(80vw, 1000px));
}

@media (max-width: 768px) {
    .body-section h3 {
        text-align: center;
        width: 350px;
        font-size: 18px;
    }

    .feature h3 {
        width: 350px;
        font-size: 18px;
        text-align: center;
    }


    h1 {
        font-size: 24px;
    }

    .title {
        width: 100%;
    }

    .title h1{
        font-size: 24px;
        margin-bottom: 10px;
    }

    .title h2{
        font-size: 36px;
    }

    .mobile-header-left h1 a{
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        padding: 0px;
        margin: 0px;
        font-family: "Inter", sans-serif;
    }

    .mobile-header-left h4 a{
        font-size: 10px;
        font-weight: 200;
        color: #fff;
        padding: 0px;
        margin: 0px;
        font-family: "Inter", sans-serif;
    }

    .mobile-header-left h4{
        padding: 0px;
        margin: 0px;
    }
    .mobile-header-left{
        margin-left: 15px;
    }

    .mobile-header-right{
        margin-right: 10px;
    }

    .try-button{
        height: 35px;
        font-size: 14px;
    }

    .title-section{
        margin-left: 7%;
        margin-right: 7%;
    }


}

.mobile-header-container{
    background-color: #1042c1;
    width: 100%;
    height: 60px;
}

.search-preview-header h4{
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter", sans-serif;
    color: #fff;
}

.search-preview-header{
    width: calc(min(80vw, 300px));
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    justify-content: center;
}

.search-tool-preview{
    background-color: #fff;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: calc(min(90%, 500px));
    border-radius: 20px;
    box-shadow: 0px 4px 10px 1px rgba(0,0,0,0.25);
}

.search-time-dropdown{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.advanced h3{
    text-align: left;
}

.advanced-feature-warning{
    width: calc(min(80vw, 500px));
    text-align: left;
}

.advanced-feature-warning h3 {
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
}

.login-container{
    width: calc(min(80vw, 500px));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}