.video-snippet {
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  background: white;
  transition: box-shadow 0.2s;
}

.video-snippet:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.video-snippet-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.video-snippet-title {
  font-weight: 500;
  color: #333;
}

.video-snippet-timestamp {
  color: #666;
  font-size: 0.9em;
  background: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
}

.video-snippet-text {
  color: #444;
  line-height: 1.5;
  margin-bottom: 12px;
  cursor: pointer;
}

.video-snippet-text:hover {
  color: #000;
}

.video-snippet-link {
  color: #1a73e8;
  text-decoration: none;
  font-size: 0.9em;
  display: inline-block;
}

.video-snippet-link:hover {
  text-decoration: underline;
} 