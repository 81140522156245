.cts-search-section {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.cts-input {
    width: 100%;
    height: 44px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    padding: 0 15px;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    margin-bottom: 15px;
}

.cts-input:focus {
    outline: none;
    border-color: #1042c1;
}

.cts-advanced-search {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 0;
}

.cts-filter-section {
    background: white;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.cts-filter-section h4 {
    margin: 0 0 12px 0;
    font-size: 15px;
    font-weight: 600;
    color: #333;
}

.cts-checkbox-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.cts-banlist-inputs {
    display: flex;
    gap: 8px;
}

.cts-exclude-input {
    flex: 1;
    height: 36px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    padding: 0 12px;
    font-size: 14px;
    background-color: white;
}

.cts-exclude-input:focus {
    outline: none;
    border-color: #1042c1;
}

.cts-banlist {
    margin-top: 12px;
}

.cts-banlist-item {
    background-color: #f0f2f5;
    border-radius: 20px;
    padding: 6px 12px;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    margin: 0 8px 8px 0;
}

.cts-search-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.cts-button-minimal {
    background: none;
    border: none;
    padding: 8px;
    cursor: pointer;
    color: #666;
    border-radius: 4px;
}

.cts-button-minimal:hover {
    background-color: #f5f5f5;
    color: #333;
}

.cts-search-button {
    background-color: #1042c1;
    color: white;
    border: none;
    padding: 10px 24px;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: 0 2px 4px rgba(16, 66, 193, 0.2);
}

.cts-search-button:hover {
    background-color: #0d2f9e;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(16, 66, 193, 0.3);
}

/* Time range specific styles */
.cts-date-range {
    display: flex;
    gap: 12px;
    align-items: center;
}

.cts-date-input {
    flex: 1;
    height: 36px;
    padding: 0 12px;
    border-radius: 6px;
    border: 1px solid #e0e0e0;
    font-size: 14px;
    background-color: white;
}

.cts-date-input:focus {
    outline: none;
    border-color: #1042c1;
}

.cts-search-description {
    margin-bottom: 15px;
    color: #666;
    font-size: 14px;
    line-height: 1.5;
}

.cts-search-description p {
    margin: 0;
}

.cts-search-layout {
    display: flex;
    gap: 60px;
    align-items: flex-start;
    padding: 10px 0;
}

.cts-search-copy {
    flex: 1;
    padding-right: 20px;
}

.cts-search-copy h3 {
    color: #1042c1;
    font-size: 28px;
    font-weight: 600;
    margin: 0 0 20px 0;
}

.cts-search-copy p {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 24px 0;
}

.cts-feature-list {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.cts-feature-item {
    color: #4a4a4a;
    font-size: 15px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    gap: 8px;
}

.cts-search-interface {
    flex: 1;
    min-width: 450px;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 12px;
}

/* Add a media query for mobile responsiveness */
@media (max-width: 768px) {
    .cts-search-layout {
        flex-direction: column;
        gap: 24px;
    }

    .cts-search-copy {
        padding-right: 0;
    }

    .cts-search-interface {
        min-width: 100%;
    }
} 

/* Style the plus button */
.cts-banlist-inputs button {
    background: none;
    border: none;
    color: #1042c1;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cts-banlist-inputs button:hover {
    background-color: #f5f5f5;
}

/* Add specific style overrides only for border behavior */
.checkbox-container input[type="checkbox"] {
    border: 2px solid #d1d1d1 !important;
}

.checkbox-container input[type="checkbox"]:checked {
    border: 2px solid #1042c1 !important;
}

.cts-control-link {
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.cts-control-link a {
    color: #1042c1;
    font-size: 14px;
    text-decoration: none;
}

.cts-control-link a:hover {
    color: #0d2f9e;
    text-decoration: underline;
}