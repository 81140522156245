/* h1 {
    text-align: left;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    padding: 0;
    margin: 0;
} */

.tagline {
    text-align: left;
    color: #A9A9A9;

}

.tagline-underlined{
    text-align: left;
    text-decoration: underline;
    text-underline-offset: 16px;
    color: #1042c1;
}

.twitter-landing{
    width: 88%;
    max-width: 1200px;
}

.twitter-landing-mobile{
    width: 95%;
}

.twitter-landing-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.twitter-input{
    width: 100%;
    height: 50px;
    border-radius: 15px;
    border: 1px solid #A9A9A9;
    padding-left: 10px;
    text-align: center;
    background-color: #F8F8F8;
    font-family: "Inter", sans-serif;
    font-size: 16px;
}

.twitter-sign-up-input:focus{
    outline: none;
    border: 1px solid #000;
}

.twitter-sign-up-button-contents{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
}

.twitter-sign-up-button{
    background-color: #1042c1;
    border: none;
    color: #fff;
    border-radius: 20px;
    height: 40px;
    width: 200px;
    max-width: 90vw;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}

.twitter-sign-up-button-subtext{
    text-align: center;
    color: #828282;
    padding-top: 12px;
}

.twitter-search-button{
    background-color: #1042c1;
    border: none;
    color: #fff;
    border-radius: 20px;
    height: 40px;

    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.twitter-search-button.standard{
    width: 140px;
    max-width: 30vw;
}

.twitter-search-button.mobile{
    width: 100%;
}

.twitter-search-button.wide{
    width: 100%;
}

.twitter-search-button:hover{
    background-color: #0D2C8C;
}

.search-options{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.search-options-buttons{
    display: flex;
    align-items: center;
    gap: 5px;
}

.dropdown-button{
    border: none;
    background-color: #F8F8F8;
    color: black;
    height: 40px;
    width: 100px;
    max-width: 30vw;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.dropdown-button:hover{
    background-color: #E8E8E8;
}

.time-dropdown{
    display: flex;
    flex-direction: column;
    background-color: #F8F8F8;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 1000;
}

.search-time-dropdown-container{
    display: flex;
    align-items: center;
    gap: 5px;
}

.search-time-dropdown{
    background-color: #fff;
    border: none;
    display: flex;
}

.search-options-button{
    size: 40px;
    padding: 5px;
    border-radius: 30px;
    margin-right: 5px;
}

.search-options-button:hover{
    background-color: #E8E8E8;
}

.twitter-search-container{
    width: 90%;
    max-width: 1000px;
}

.twitter-search-section{
    display: flex;
    flex-direction: column;
}

.clickable{
    cursor: pointer;
}

.bad-email{
    color: red;
    border: 1px solid red;
}

.bad-email:focus{
    border: 1px solid red;
    outline: none;
}

.twitter-sign-up{
    display: flex;
    flex-direction: column;
    width: 600px;
    max-width: 90vw;
}

.bad-email-text{
    color: red;
    margin-bottom: 10px;
    height: 20px;
}

.email-valid{
    margin-top: 30px;
}

.twitter-search-header white{
    color: #fff;
    background-color: none;
}


.twitter-search-description{
    margin-top: 10px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 300;
}

.advanced-search{
    margin-left: 4px;
}

.advanced h3{
    color: #1042c1;
    font-size: 16px;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
}

.advanced p{
    color: #8B8B8B;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    margin-bottom: 0px;
}

button {
    cursor: pointer;
    color: black;
}

.checkbox-section{
    gap: 10px;
}

.checkbox-section input{
    padding: 0;
    margin: 0;
}

.checkbox-container{
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.checkbox{
    width: 20px;
    padding: 0px;
    margin: 0px;
}

.rec-tag{
    font-size: 12px;
    color: #6993bd;
    background-color: #F8F8F8;
    border-radius: 15px;
    /* padding: 1px; */
    padding-left: 5px;
    padding-right: 5px;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #A9A9A9;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    color: white;
}

input[type="checkbox"]:checked {
    background-color: #1042c1;
    /* border: 1px solid #1042c1; */
    color: white;
}

input[type="checkbox"]:checked::before {
    content: "✔";
    display: block;
    text-align: center;
    line-height: 20px;
    color: white;
}

input[type="checkbox"]:checked + label::after {
    content: '✓';
    display: inline-block;
    position: absolute;
    left: 4px;
    top: -2px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
}

input[type="checkbox"]:focus {
    border: none;
    outline-color: #1042c1;
    color: white;
}

.exclude-input{
    border: 1px solid #A9A9A9;
    border-radius: 4px;
    padding-left: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
    max-width: 350px;
    width: 100%;
}

.exclude-input:hover{
    border: 1px solid black;
}

.banlist-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F8F8F8;
    border-radius: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    gap: 5px;
    padding-left: 10px;
}

.banlist{
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.add-ban-button{
    background: none;
    border: none;
    color: #8B8B8B;
    border-radius: 20px;
    padding: 5px;
    
}

.add-ban-button:hover{
    background-color: #E8E8E8;
}

.banlist-inputs{
    align-items: center;
    display: flex;
    gap: 5px;
}

.banlist-section{
}

.saved-searches-button{
    background: none;
    border: 1px solid #A9A9A9;
    color: #A9A9A9;
    border-radius: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}

.ssb-closed:hover{
    background-color: #F8F8F8;
}

.save-button-big{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    background-color: #E8E8E8;
    height: 40px;
    cursor: pointer;
    border: none;
    font-size: 12px;
}

.save-button-big:hover{
    background-color: #D8D8D8;
}

.spared-link{
    color: #1042c1;
    font-size: 12px;
    font-weight: 400;
}

.modal {
    height: 400px;
    width: calc(min(90vw, 400px));
    display: block;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
    height: fit-content;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.modal-body {
    margin-top: 16px;

}

.search-item{
    color: #1042c1;
    font-size: 16px;
    font-weight: 600;

    display: flex;

    justify-content: space-between;

    align-items: center;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;

    padding-right: 2px;

    border-radius: 15px;

    gap: 5px;

    margin-top: 5px;
    margin-bottom: 5px;

    background-color: #F8F8F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid #ECECEC;
    min-width: 300px;
    max-width: 375px;
}


.search-item:hover{

    background-color: #F8F8F8;
}

.search-item-container{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 5px 15px;
    justify-content: space-between; /* Ensure items take up as much space as possible */
}


.search-link{
    text-overflow: ellipsis;
    max-width: 200px;
    white-space: nowrap; /* Prevent text wrapping */
}

.edit-button{
    padding: 10px;
    border-radius: 20px;
    background-color: inherit;
    border: none;
}

.edit-button:hover{
    background-color: #D8D8D8;
}

.new-search-button-container{
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.new-search-button{
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background: inherit;
    color: #A9A9A9;
}

.new-search-button:hover{
    background-color: #F8F8F8;
}

.new-search-button-text{
    font-size: 16px;
    font-weight: 400;
}

.new-search-button{
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    border: none;
    background: inherit;
    color: #A9A9A9;
}

.new-search-button:hover{
    background-color: #E8E8E8;
}

.new-search-button-text{
    font-size: 16px;
    font-weight: 400;
}

.saved-searches-header{
    margin-left: 10px;
}

.saved-searches-header p{
    color: #8B8B8B;
    font-size: 12px;
    font-weight: 400;
}

.saved-searches-header h4{
    color: black;
    font-size: 16px;
    font-weight: 600;
    margin-top: 0px;
} 

.save-cancel-small{
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.save-cancel-small .caption{
    font-size: 12px;
    font-weight: 400;
    font-style: italic;
}

.button-gray{
    background-color: #E8E8E8;
    border: none;
    border-radius: 15px;
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}

.button-gray:hover{
    background-color: #D8D8D8;
}