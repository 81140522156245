.filings-wrapper {
  height: 100%;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.filings-wrapper h1 {
  color: #333;
  margin-bottom: 24px;
  font-size: 28px;
  font-weight: 500;
}

.filings-container {
  display: flex;
  flex-direction: row;
  gap: 32px;
  height: 100%;
  width: 100%;
  padding: 0;
}

.filing-section {
  flex: 1;
  min-width: 50%;
  max-width: none;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filing-section h2 {
  font-size: var(--font-size-lg);
  font-weight: var(--font-weight-semibold);
  color: var(--gray-900);
  margin: 0;
}

.filing-category {
  display: flex;
  flex-direction: column;
  background: var(--card-bg);
  border-radius: var(--card-radius);
  border: var(--card-border);
  box-shadow: var(--card-shadow);
  padding: var(--card-padding-md);
}

.filings-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  flex: 1;
}

.filings-table th {
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #eee;
  color: #666;
  font-weight: 500;
  font-size: 14px;
}

.filings-table td {
  border-bottom: 1px solid #eee;
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 12px;
}

.filings-table tr:last-child td {
  border-bottom: none;
}

.filing-buttons {
  display: flex;
  gap: 12px;
  margin-top: 12px;
}

.view-more, .view-less {
  flex: 1;
  padding: 12px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  color: #2563eb;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.view-more:hover, .view-less:hover {
  background: #f1f5f9;
  text-decoration: none;
}

.filing-row {
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.filing-row:hover {
  background-color: #f1f5f9;
}

.filings-table a {
  color: inherit;
  text-decoration: none;
  font-weight: inherit;
}

.filings-table a:hover {
  text-decoration: none;
} 