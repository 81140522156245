.homepage {
    min-height: 100vh;
    padding-top: 2rem;
}

.homepage-hero {
    text-align: center;
    padding: 4rem 2rem;
    background: linear-gradient(to bottom, #f8f9fa, #ffffff);
}

.homepage-title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #1a1a1a;
}

.homepage-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.homepage-search {
    margin: 0 auto;
}

.homepage-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
}

.featured-companies {
    margin-bottom: 3rem;
}

.company-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 2rem;
    margin-top: 1.5rem;
}

.company-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    padding: 1.5rem;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
}

.company-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.company-card-logo {
    width: 48px;
    height: 48px;
    object-fit: contain;
    margin-bottom: 1rem;
}

.company-metrics {
    display: flex;
    gap: 1rem;
    margin: 0.5rem 0;
}

.metric {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: #666;
}

.trend-up {
    color: #4caf50;
}

.trend-down {
    color: #f44336;
}

.company-highlight {
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.5rem;
}

@media (max-width: 768px) {
    .homepage-hero {
        padding: 2rem 1rem;
    }

    .homepage-title {
        font-size: 2rem;
    }

    .company-grid {
        grid-template-columns: 1fr;
    }
} 