.pricing-container {
    display: flex;
    justify-content: center;
    gap: 0px;
    margin-top: 15px;
    margin-bottom: 20px;
}

.pricing-card {
    max-width: 400px;
    width: 320px;
    background-color: #ffffff;
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
    border: #f6f6f6 0.5px;
    border-radius: 20px;
    font-family: "Inter", sans-serif;
}

.pricing-card-mobile {
    width: 340px;
}

.pricing-box {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 25px;
    margin-bottom: 0px;

}

.pricing-box-mobile{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}


.feature-item{
    display: flex;
    align-items: left;
    gap: 5px;
}


.cta-button{
    background-color: #1042c1;
    color: #fff;
    border-radius: 15px;
    width: 100%;
    font-weight: 600;
    height: 40px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    border: none;
}

.cta-button:hover{
    background-color: #0d2f9e;
}

.neutral{
    background-color: #fff;
    color: black;
    border: #f6f6f6 1px;
}

.neutral:hover{
    background-color: #f6f6f6;
}

.pricing-section{
    display: flex;
    justify-content: left;
    gap: 10px;
    align-items: center;
}

.price{
    color: black;
    font-size: 36;
    font-weight: 600;
}

.name{
    color: #737373;
    font-size: 18px;
    font-weight: 600;
    padding: 0px;
    margin: 0px;
}

.feature-des{
    color: #737373;
    font-size: 16px;
    font-weight: 600;
}

.feature-section{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.extra{
    background-color: #7234B1;
}

.extra:hover{
    background-color: #572789;
}

.pricing-footer{
    color: #737373;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    margin-top: 20px;
    font-style: italic;
}