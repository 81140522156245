/* Common container width */
.container.wide-width,
.landing-section {
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  padding: 0 6rem;
}

.landing-page{
    font-family: "Inter", sans-serif;
    background-color: #fff;
}

#landing-page h2{
  font-size: 30px;
}


/* Navbar styles */
.landing-header {
  height: 60px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  display: flex;
  align-items: center;
  gap: 15px;
}

.navbar-title:hover {
  color: #fff;
}

/* Mobile navbar adjustments */
@media screen and (max-width: 767px) {
  .landing-header {
    padding-left: 16px;
    padding-right: 16px;
    height: 60px;
  }

  .mobile-controls {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .hamburger-menu {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  .mobile-demo-btn {
    padding: 8px 12px;
    font-size: 14px;
  }
}

/* Header/Intro styles */
.intro {
  display: table;
  width: 100%;
  padding: 0;
  height: 400px;
  text-align: left;
  position: relative; /* Add this to contain the video */
  overflow: hidden; /* Add this to ensure video doesn't overflow */
  z-index: 2;
}

.sine-background {
    background-color: #08103d;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: visible;
    z-index: 1;
}

.sine-background::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(../../public/img/dalle-sine2.png) center center no-repeat;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.075;
    z-index: -1;
    pointer-events: none;
}


.intro video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

/* Update container to ensure content is above video */
.intro .container {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.intro h1 {
  font-family: "Inter", sans-serif;
  color: #fff;
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
  line-height: 1.2;
}

.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
  padding-top: 20px;
}

.intro-text {
  max-width: 650px;
  padding-top: 140px;
  padding-bottom: 100px;
  text-align: left;
}

.intro-buttons {
  display: flex;
  gap: 20px;
  flex-direction: row;
  width: 100%;
}

/* Landing section styles */
.landing-section {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.landing-content {
  max-width: 800px;
  margin-bottom: 1rem;
}

.landing-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #111827;
}

.landing-section p {
  font-size: 1.125rem;
  line-height: 1.75;
  color: #4B5563;
  margin-bottom: 0rem;
}

.landing-bottom-text {
  font-size: 1.125rem;
  line-height: 1.75;
  color: #4B5563;
  margin-bottom: 1rem;
}

/* Split layout styles */
.landing-section-split {
  flex-direction: row;
  gap: 6rem;
  align-items: flex-start;
  justify-content: space-between;
}

.landing-content-split {
  flex: 0 1 600px;
  margin-bottom: 0;
  max-width: 600px;
}

.landing-image-split {
  flex: 0 1 650px;
  display: flex;
  align-items: center;
}

.landing-image-split img,
.landing-image img {
  width: 100%;
  height: auto;
}

/* Button styles */
.landing-button {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  color: #2563EB;
  text-decoration: none;
  font-weight: 800;
  transition: color 0.2s;
  position: relative;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font: inherit;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.landing-button:hover {
  color: #1D4ED8;
}

.landing-button::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #1D4ED8;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

.landing-button:hover::after {
  transform: scaleX(1);
}

/* Bullet points */
.landing-bullets {
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
}

.landing-bullets li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
  color: #4B5563;
  font-size: 1.125rem;
  line-height: 1.75;
}

.landing-bullets li::before {
  content: "•";
  position: absolute;
  left: 0;
  color: #2563EB;
}

/* Mobile Menu styles */
.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1042c1;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.mobile-menu-content {
  padding: 16px;
  padding-top: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.mobile-menu-nav {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 20px 0;
}

.mobile-menu-nav a {
  color: white;
  text-decoration: none;
  font-size: 24px;
  font-weight: 500;
}

.mobile-menu-buttons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 32px;
}

.mobile-menu-buttons button {
  width: 100%;
  height: 56px;
  font-size: 18px;
  font-weight: 500;
}

.mobile-menu-btn {
  padding: 16px 24px !important;
}

.close-menu {
  background: none !important;
  border: none !important;
}

/* Responsive styles */
@media (max-width: 768px) {
  .container.wide-width,
  .landing-section {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .landing-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .landing-section-split {
    flex-direction: column;
    gap: 2rem;
  }

  .landing-content-split,
  .landing-image-split {
    flex: 1;
    max-width: 100%;
  }

  .landing-image-split,
  .landing-image {
    width: 100%;
  }

  .btn.square.blue.wide.tall {
    width: 100% !important;
    max-width: 100%;
  }

  .landing-title {
    font-size: 1.5rem;
  }

  .intro-buttons {
    flex-direction: column;
    gap: 15px;
  }

  .intro-buttons button {
    width: 100% !important;
    max-width: 100%;
  }

  .responsive-title {
    font-size: 48px !important;
  }

  .mobile-image {
    width: 100% !important;
    max-width: none !important;
  }

  .desktop-image {
    display: none;
  }

  .landing-button-square {
    width: 100% !important;
    max-width: 100%;
  }
}

/* Desktop-only styles */
@media (min-width: 769px) {
  .mobile-image {
    display: none;
  }

  .responsive-title {
    font-size: 60px;
  }
}

/* Add this to ensure smooth scrolling behavior page-wide */
html {
  scroll-padding-top: 60px;
  /* remove scroll-behavior: smooth; */
}

#get-started {
    padding-top: 0;
    padding-bottom: 0;
}


/* Add these styles for the coverage section */
.coverage-container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 6rem;
  width: 100%;
}

@media (max-width: 768px) {
  .coverage-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

#quote-search {
    padding-bottom: 6rem;
}

@media (max-width: 768px) {
    #quote-search {
        padding-bottom: 3rem;
    }
}



.philosophy-quotes-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 1rem;
  position: relative;
}

/* Adjust card spacing */
.philosophy-quote-card:first-child {
  margin-top: 0;
}

.philosophy-quote-card {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 2.5rem;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.philosophy-quote-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.philosophy-quote-text {
  font-size: 1.25rem;
  color: #111827;
  line-height: 1.75;
  margin-bottom: 2rem;
  font-style: italic;
  flex: 1;
  overflow: visible;
}

.philosophy-quote-author {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
}

.philosophy-quote-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  flex-shrink: 0;
}

.philosophy-quote-name {
  font-weight: 600;
  color: #1042c1;
  font-size: 1.125rem;
}

/* Desktop styles */
@media (min-width: 1024px) {
  .philosophy-quotes-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5rem;
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .philosophy-quote-card {
    padding: 1.5rem;
    margin: 0;
    border-radius: 8px;
    height: auto;
    min-height: 0;
  }

  .philosophy-quote-text {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
    display: block;
    width: 100%;
  }

  .philosophy-quote-author {
    padding-top: 1rem;
    width: 100%;
  }

  .philosophy-quote-image {
    width: 50px;
    height: 50px;
    min-width: 50px;  /* Prevent image from shrinking */
  }
}

/* Add these new styles */
.landing-button-square {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;
    margin-top: 24px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.landing-button-square:hover {
    transform: translateY(-2px);
}

/* Keep any existing width-related rules from .landing-button that you want to preserve */
@media (max-width: 768px) {
    .landing-button-square {
        width: 100%;
    }
}

/* Add these styles at the end of your Landing.css file */

.footer {
    color: white;
    width: 100%;
    /* background-color: #1042c1; */
    z-index: 1;
}

.footer-content {
    max-width: 1600px;
    margin: 0 auto;
    padding: 4rem 6rem;
    display: flex;
    gap: 6rem;
}

.footer-company {
    flex: 0 0 300px;
}

.footer-logo {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 1rem;
}

.footer-logo img {
    height: 30px;
    width: 30px;
}

.footer-logo span {
    font-size: 18px;
    font-weight: 600;
}

.footer-email {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
}

.footer-nav {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.footer-nav-section h3 {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1.0rem;
}

.footer-nav-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-nav-section ul li {
    margin-bottom: 0.75rem;
}

.footer-nav-section ul li a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    font-size: 14px;
    transition: color 0.2s;
}

.footer-nav-section ul li a:hover {
    color: white;
}

.footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom-content {
    max-width: 1600px;
    margin: 0 auto;
    padding: 1.5rem 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-legal {
    display: flex;
    gap: 2rem;
    align-items: center;
    font-size: 14px;
}

.footer-legal a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: color 0.2s;
}

.footer-legal a:hover {
    color: white;
}

.footer-social {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.footer-social a img {
    height: 20px;
    width: 20px;
    filter: brightness(0) invert(1);
    opacity: 0.8;
    transition: opacity 0.2s;
}

.footer-social a:hover img {
    opacity: 1;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        gap: 3rem;
        padding: 3rem 1.5rem;
    }

    .footer-company {
        flex: none;
    }

    .footer-nav {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .footer-bottom-content {
        flex-direction: column;
        gap: 1.5rem;
        padding: 1.5rem;
    }

    .footer-legal {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        text-align: center;
    }

    .footer-social {
        justify-content: center;
    }
}

.footer-email-link {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    transition: color 0.2s;
}

.footer-email-link:hover {
    color: white;
}

.logo-container {
    cursor: pointer;
}

@media (max-width: 768px) {
    .logo-grid {
        padding-top: 3rem;
    }
}



/* Quote Display Styles */
.quote-container {
    border-radius: 12px;
    padding: 2rem;
    margin-top: 2rem;
    transition: all 0.3s ease;
}

.quote-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin-bottom: 1.5rem; */
    padding-bottom: 0.25rem;
    /* border-bottom: 2px solid #1042c1; */
}

.quote-header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1e293b;
    margin: 0;
}

.company-name {
    color: #1042c1;
}

.close-button {
    color: #64748b !important;
    padding: 8px !important;
}

.close-button:hover {
    background-color: #f1f5f9 !important;
}

.quotes-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.quote-card {
    background: white;
    padding: 1.5rem;
    border-radius: 4px;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
    border-top: 4px solid #1042c1;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}

/* .quote-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
} */

.quote-card p {
    margin: 0;
    color: #334155;
    font-size: 1rem;
    line-height: 1.6;
}

/* Mobile styles */
@media (max-width: 768px) {
    .quote-container {
        margin-top: 1rem;
        border-radius: 8px;
        padding: 0;
    }

    .quote-header {
        margin-bottom: 0rem;
        padding-bottom: 0.75rem;
        border-bottom: none;
    }

    .quote-header h2 {
        font-size: 1.25rem;
    }

    .quotes-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .quote-card {
        padding: 1.25rem;
    }
}

/* User Testimonials */
.user-testimonials-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.user-testimonial-card {
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  padding: 1.25rem;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  height: 140px;
  display: flex;
  flex-direction: column;
}

.user-testimonial-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #1042c1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.user-testimonial-title {
  font-size: 0.8125rem;
  color: #1042c1;
  font-weight: 600;
  padding-top: 0.5rem;
  border-top: 1px solid #e5e7eb;
  margin-top: auto;
}

.user-testimonial-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px -2px rgba(16, 66, 193, 0.1);
  border-color: #1042c1;
}

.user-testimonial-card:hover::before {
  opacity: 1;
}

/* Mobile styles */
@media (max-width: 768px) {
  .user-testimonials-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .user-testimonial-card {
    height: auto;
    min-height: 0;
    padding: 1rem;
  }
}

/* Tablet styles */
@media (min-width: 769px) and (max-width: 1024px) {
  .user-testimonials-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Stats Section with blue background */
.testimonials-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
}

/* Add blue background to the entire section */
.testimonials-section {
  padding: 4rem 0 1rem;
  margin: 0;
  color: white;
  position: relative;
  overflow: visible;
}



.testimonials-section .landing-title,
.testimonials-section .landing-subtitle {
  color: white;
}

.market-stats-banner {
  display: flex;
  gap: 5rem;
  padding: 0;
  margin-bottom: 1rem;
  max-width: 700px;
}

.market-metric {
  flex: 1;
}

.metric-number {
  font-size: 3.5rem;
  font-weight: 800;
  color: white;
  margin-bottom: 1rem;
  line-height: 1;
  letter-spacing: -0.02em;
}

.metric-description {
  font-size: 1.125rem;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
  margin: 0;
  max-width: 300px;
}

/* Update testimonial cards for blue background */
.user-testimonial-card {
  background-color: white;
  border: none;
  border-radius: 8px;
  padding: 1.25rem;
  transition: all 0.2s ease-in-out;
  position: relative;
  overflow: hidden;
  height: 140px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-testimonial-quote {
  color: #1e293b;  /* Keep dark text on white cards */
}

.user-testimonial-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

/* Mobile styles */
@media (max-width: 768px) {
  .testimonials-section {
    padding: 3rem 0 1rem;
    margin: 0;
  }

  .market-stats-banner {
    flex-direction: column;
    gap: 2.5rem;
    padding: 0;
  }

  .metric-number {
    font-size: 3rem;
  }

  .metric-description {
    font-size: 1rem;
    max-width: none;
  }
}

/* Update these carousel styles */
.testimonial-carousel {
  position: relative;
  width: 100%;
  max-width: 800px;
  padding-bottom: 3rem;
}

.testimonial-carousel .carousel-container {
  margin-bottom: 1rem;
}

/* Container for the slides */
.testimonial-slides-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  transition: transform 0.5s ease-in-out;
  cursor: grab;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.testimonial-slides-container:active {
  cursor: grabbing;
}

/* Individual slide */
.testimonial-slide {
  min-width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.testimonial-slide.active {
  opacity: 1;
}

/* Quote card */
.testimonial-content {
  background: rgba(255, 255, 255, 0.98);
  border-radius: 8px;
  padding: 2.5rem;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  border-top: 8px solid #1042c1;
}

.testimonial-quote {
  font-size: 1.25rem;
  color: #1e293b;
  line-height: 1.5;
  margin-bottom: 1.5rem;
  font-weight: 400;
}

.testimonial-author {
  color: #1042c1;
  font-weight: 400;
  font-size: 1rem;
  font-style: italic;
}

/* Progress dots */
.testimonial-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.testimonial-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.testimonial-dot.active {
  background: white;
  transform: scale(1.2);
}

/* Mobile styles */
@media (max-width: 1200px) {
  .testimonial-nav-prev {
    left: 20px;
  }

  .testimonial-nav-next {
    right: 20px;
  }
}

@media (max-width: 768px) {
  .testimonial-nav-button {
    width: 36px;
    height: 36px;
  }

  .testimonial-quote {
    font-size: 1.25rem;
  }
}

/* Add this style for the philosophy section */
#philosophy {  
  padding: 4rem 0 0rem;
  overflow: visible;
}

/* Generic Carousel Styles */
.carousel {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 2rem auto 0;
  overflow: hidden;
}

.carousel-container {
  display: flex;
  transition: transform 0.5s ease-in-out;
  cursor: grab;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.carousel-container:active {
  cursor: grabbing;
}

.carousel-slide {
  min-width: 100%;
  padding: 0 0rem;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.carousel-slide.active {
  opacity: 1;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
}

.carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.carousel-dot.active {
  background: #1042c1;
  transform: scale(1.2);
}

/* Philosophy Carousel Specific Styles */
.philosophy-carousel {
  margin-top: 3rem;
  margin-bottom: 2.5rem;
  padding-bottom: 2rem;
  max-width: 900px;
  position: relative;
}

.philosophy-slides-container {
  gap: 0;
}

.philosophy-slide {
  padding: 0 1.5rem;
  opacity: 1;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Update existing philosophy quote card styles */
.philosophy-quote-card {
  height: auto;
  opacity: 1;
  transition: transform 0.3s ease;
  margin: 0 auto;
  max-width: 800px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 2.5rem;
  border-top: 8px solid #1042c1;
}

.philosophy-quote-content {
  width: 100%;
  height: 100%;
}

.philosophy-quote-text {
  font-size: 1.25rem;
  color: #111827;
  line-height: 1.75;
  margin-bottom: 2rem;
  font-style: italic;
}

/* Mobile styles */
@media (max-width: 768px) {
  .philosophy-carousel {
    max-width: 100%;
    padding: 0 1rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .philosophy-slide {
    padding: 0 0.5rem;
  }

  .philosophy-quote-card {
    padding: 1.5rem;
  }

  .philosophy-quote-text {
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
  }
}

/* Testimonials section specific overrides */
.testimonials-section .carousel-dot {
  background: rgba(255, 255, 255, 0.4);
}

.testimonials-section .carousel-dot.active {
  background: white;
}

/* Add these new styles */
.philosophy-carousel-wrapper {
  width: 100%;
  padding: 0;
  margin: 0 auto;
  position: relative;
}

#philosophy .landing-section {
  overflow: visible; /* Ensure dots are not cut off */
}

/* Update carousel container for philosophy section */
.philosophy-carousel .carousel-container {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 1rem;
}

/* Ensure proper spacing for dots */
.philosophy-carousel .carousel-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
  z-index: 10;
}

.philosophy-carousel .carousel-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.philosophy-carousel .carousel-dot.active {
  background: #1042c1;
  transform: scale(1.2);
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .philosophy-carousel {
    margin-bottom: 3rem;
    padding-bottom: 2.5rem;
  }
  
  #philosophy {
    padding: 0rem 0 0rem;
  }
}
 