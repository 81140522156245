.support-rep-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
.support-reps-page  h1 {
    color: #2c3e50;
    font-size: 32px;
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 2px solid #3498db;
    padding-bottom: 15px;
  }
  
  .rep-details {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 25px;
    margin-bottom: 30px;
  }
  
  .rep-details h2 {
    color: #2c3e50;
    font-size: 28px;
    margin-bottom: 20px;
  }
  
  .rep-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 24px;
    margin-bottom: 24px;
  }
  
  .rep-name {
    font-size: 28px;
    color: #2c3e50;
    margin-bottom: 16px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 8px;
  }
  
  .rep-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    margin-bottom: 24px;
  }
  
  .stat-item {
    background-color: #f8f9fa;
    border-radius: 6px;
    padding: 12px;
  }
  
  .stat-label {
    font-size: 14px;
    color: #7f8c8d;
    margin-bottom: 4px;
  }
  
  .stat-value {
    font-size: 18px;
    font-weight: bold;
    color: #34495e;
  }
  
  .feedback-section {
    margin-top: 30px;
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
  }
  
  .feedback-header {
    font-size: 24px;
    color: #2c3e50;
    margin-bottom: 20px;
    border-bottom: 2px solid #3498db;
    padding-bottom: 10px;
  }
  
  .feedback-content {
    margin-bottom: 20px;
  }
  
  .feedback-content h4 {
    font-size: 18px;
    color: #34495e;
    margin-bottom: 10px;
  }
  
  .feedback-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-left: 15px;
    padding-left: 15px;
    border-left: 3px solid #3498db;
  }
  
  .support-reps-page  button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 12px 20px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    display: block;
    margin: 30px auto 0;
  }
  
  .support-reps-page button:hover {
    background-color: #2980b9;
  }
  
  .all-reviews {
    margin-top: 30px;
  }
  
  .review {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .review p {
    margin-bottom: 10px;
    line-height: 1.6;
  }
  
  .review a {
    color: #3498db;
    text-decoration: none;
    font-weight: bold;
  }
  
  .review a:hover {
    text-decoration: underline;
  }